import React from "react"
import { gettext } from "ttag"
import LogoYoutube from "react-ionicons/lib/LogoYoutube"
import LogoVimeo from "react-ionicons/lib/LogoVimeo"
import LogoFacebook from "react-ionicons/lib/LogoFacebook"

import {
	matchShortcode
} from "services/functions"

const labels = {
	YoutubeVideo: `Youtube video`,
	VimeoVideo: `Vimeo video`,
	LinkForFacebookSharing: `Link for Facebook sharing`,
}

const TaskTypeOptions = ({task, parseTaskType, taskType}) => {

  if(matchShortcode(task.name))
    return false;

  return (
    <ul className="task-type-options list-inline list-unstyled">
      {task.parsed && task.parsed.youtube && <li><button title={gettext(labels.YoutubeVideo)} onClick={e => {e.preventDefault(); parseTaskType(taskType, "youtube");}}><LogoYoutube color="#ccc"/></button></li>}
      {task.parsed && task.parsed.vimeo && <li><button title={gettext(labels.VimeoVideo)} onClick={e => {e.preventDefault(); parseTaskType(taskType, "vimeo");}}><LogoVimeo color="#ccc"/></button></li>}
      {task.parsed && task.parsed.fbshare && <li><button title={gettext(labels.LinkForFacebookSharing)} onClick={e => {e.preventDefault(); parseTaskType(taskType, "fbshare");}}><LogoFacebook color="#ccc"/></button></li>}
    </ul>
  );
}

export default TaskTypeOptions