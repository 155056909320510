/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	registerRequestAction,
	registerClearAction,
	registerValidationErrorAction
} from "./actions"

/* THE COMPONENT */
import Register from "./component" 

const mapStateToProps = state => ({
  register: state.register,
})

const actions = {
	registerRequestAction,
	registerClearAction,
	registerValidationErrorAction,
}

export default connect(mapStateToProps, actions)(Register)