import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Users from "./component"

import {
	challengeStatsUsersRequestAction,
	challengeStatsUsersClearAction,
	challengeStatsUsersFilterSet,
} from "./actions"

const mapStateToProps = state => ({
	token: state.client.token,
  challengeStatsUsers: state.challengeStatsUsers,
})

const actions = {
	challengeStatsUsersRequestAction,
	challengeStatsUsersClearAction,
	challengeStatsUsersFilterSet,
}

export default withRouter(connect(mapStateToProps, actions)(Users))