import querystring from "querystring"
const { REACT_APP_API_ENDPOINT } = process.env

export function registerApi({displayName, email, password, language}) {
  return fetch(REACT_APP_API_ENDPOINT + "/auth/register", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({displayName: displayName.trim(), email: email.trim(), password: password.trim(), language: language.trim()})
  })
  .then(response => {
    if(response.status === 200)
      return response;

    return response.json()
  })
}

export function loginApi({ email, password }) {
  return fetch(REACT_APP_API_ENDPOINT + "/auth/signin", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({email: email.trim(), password: password.trim()})
  })
  .then(response => response.json())
  .then(json => json)
}

export function resetPasswordApi({ email }) {
  return fetch(REACT_APP_API_ENDPOINT + "/auth/resetPassword", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({email, language: "en"})
  })
  .then(response => {
    if(response.status === 200)
      return response;

    return response.json()
  })
}

export function changePasswordApi({ token, currentPassword, newPassword }) {
  return fetch(REACT_APP_API_ENDPOINT + "/users/me/password", {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({currentPassword, newPassword})
  })
  .then(response => {
    if(response.status === 200)
      return response;

    return response.json()
  })
}

export function facebookLoginApi({ accessToken }) {
  return fetch(REACT_APP_API_ENDPOINT + "/auth/signin/facebook", {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({code: accessToken})
  })
  .then(response => response.json())
  .then(json => json)
}

export function apiTaskTogglePut({token, taskId, programId, action, inputValue}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/programs/${programId}/tasks/${taskId}/${action}`, {
    method: "PUT",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({inputValue: inputValue})
  })
  .then(response => response.json())
  .then(json => json)
}

export function apiDailyTasksGet({token}) {
  return fetch(REACT_APP_API_ENDPOINT + "/users/me/programs/current/tasks/today", {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}

export function apiCurrentProgramTasksGet({token}) {
  return fetch(REACT_APP_API_ENDPOINT + "/users/me/programs/current/tasks", {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}

export function apiCurrentProgramGet({token}) {
  return fetch(REACT_APP_API_ENDPOINT + "/users/me/programs/current", {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeCreateApi({token, name, duration}) {
  return fetch(REACT_APP_API_ENDPOINT + "/challenges", {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({name: name, duration: duration})
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeGetApi({token, challengeId}) {

  var headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  }

  if(token) {
    headers = { ...headers, Authorization: "Bearer " + token} 
  }

  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}`, {
    method: "GET",
    headers,
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeUpdateApi({token, challenge}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challenge.id}`, {
    method: "PUT",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({name: challenge.name, duration: challenge.duration})
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeDeleteApi({token, challengeId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}`, {
    method: "DELETE",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
  .then(response => response)
}

export function challengeActivateToggleApi({token, challenge}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challenge.id}/${challenge.active ? "deactivate" : "activate"}`, {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeTasksGetApi({token, challengeId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}/tasks`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeTasksCreateApi({token, challengeId, task}) {
  const {category, dayEnd, dayStart, inputType, name, points, completeTime} = task
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}/tasks`, {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({category, dayEnd, dayStart, inputType, name, points, completeTime})
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeTasksUpdateApi({token, challengeId, task}) {
  const {category, dayEnd, dayStart, inputType, name, points, completeTime} = task
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}/tasks/${task.id}`, {
    method: "PUT",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({category, dayEnd, dayStart, inputType, name, points, completeTime})
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeTasksDeleteApi({token, challengeId, taskId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}/tasks/${taskId}`, {
    method: "DELETE",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
  .then(response => response)
}

export function challengesGetApi({token}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/my`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
  .then(response => response.json())
  .then(response => response)
}

export function challengeAcceptApi({token, challengeId, referral}) {
	var qs = ""
	if(referral && referral['referral'] && !isNaN(referral['referral'])) {
		qs = "?" + querystring.stringify(referral)
	}
  return fetch(`${REACT_APP_API_ENDPOINT}/challenges/${challengeId}/accept${qs}`, {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
  .then(response => response.json())
  .then(response => response)
}

export function challengeStatsDaysGetApi({token, challengeId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challengestats/${challengeId}/days`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeStatsUsersGetApi({token, challengeId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challengestats/${challengeId}/users`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeStatsUserTasksGetApi({token, challengeId, userId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challengestats/${challengeId}/tasks/${userId}`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}

export function challengeStatsScoreboardGetApi({token, challengeId}) {
  return fetch(`${REACT_APP_API_ENDPOINT}/challengestats/${challengeId}/scoreboard`, {
    method: "GET",
    headers: {
      "Authorization": "Bearer " + token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(json => json)
}
