import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import SideMenu from "./component"

import {
	currentProgramAction,
} from "components/daily-tasks/actions"

const mapStateToProps = state => ({
	token: state.client.token,
	user: state.client.user,
  client: state.client,
  program: state.program,
  tasks: state.tasks,
})

const connected = connect(mapStateToProps, {
	currentProgramAction,
})(SideMenu)

export default withRouter(connected)