import React from "react"
import {
	Modal,
	Button,
} from "react-materialize"
import { t } from "ttag"

import Storage from "services/storage"

const {
	REACT_APP_COOKIE_DISCLAIMER_ACCEPT,
} = process.env

export default class AppCookieDisclaimer extends React.Component {

	state = {
		hasCheckedForCookie: false,
		cookie: false,
	}

	componentDidMount() {
		const cookie = this.fetchCookie()
		if(!cookie) {
			this.setState({
				hasCheckedForCookie: true
			})
		} else {
			this.setState({
				hasCheckedForCookie: true,
				cookie,
			})
		}

	}

	fetchCookie = () => {
		return Storage.getItem(REACT_APP_COOKIE_DISCLAIMER_ACCEPT)
	}

	setCookie = () => {
		Storage.setItem(REACT_APP_COOKIE_DISCLAIMER_ACCEPT, {accepted: 1}, true)
	}

	render() {

		if(!this.state.hasCheckedForCookie) {
			return false
		}

		if(this.state.cookie) {
			return false
		}

		return (
			<Modal
				className="app-cookie-disclaimer"
				open={true}
				bottomSheet
				options={{
					dismissible: false,
					onCloseEnd: this.setCookie
				}}
				actions={[
					<Button modal="close" className="blue lighten-1" waves="light">{t`I agree`}</Button>
				]}>
				{t`Please confirm that you agree to the use of cookies for login purposes in the client zone.`}
			</Modal>
		)
	}
} 