import {
  CHALLENGE_TASKS_GET_REQUEST,
  CHALLENGE_TASKS_CREATE_REQUEST,
  CHALLENGE_TASKS_UPDATE_REQUEST,
  CHALLENGE_TASKS_DELETE_REQUEST,
  CHALLENGE_TASK_EDIT,
  CHALLENGE_TASK_EDIT_CLEAR,
  CHALLENGE_DAY_TASK_CREATE,
  CHALLENGE_DAY_TASK_CREATE_CLEAR,
  CHALLENGE_TASK_CHANGE,
  CHALLENGE_TASK_DAYS_COLLAPSE_TOGGLE,
  CHALLENGE_TASK_ANSWER_ADD,
  CHALLENGE_TASK_ANSWER_REMOVE,
  CHALLENGE_TASK_ANSWER_CORRECT_SET_INDEX,
} from "./constants"

export function challengeTasksGetAction({ challengeId, token }) {
  return {
    type: CHALLENGE_TASKS_GET_REQUEST,
    challengeId,
    token,
  }
}

export function challengeTasksCreateAction({ challengeId, task, token, cb }) {
  return {
    type: CHALLENGE_TASKS_CREATE_REQUEST,
    challengeId,
    task,
    token,
    cb,
  }
}

export function challengeTasksUpdateAction({ challengeId, task, token, cb }) {
  return {
    type: CHALLENGE_TASKS_UPDATE_REQUEST,
    challengeId,
    task,
    token,
    cb,
  }
}

export function challengeTasksDeleteAction({ challengeId, taskId, token }) {
  return {
    type: CHALLENGE_TASKS_DELETE_REQUEST,
    challengeId,
    taskId,
    token,
  }
}

export function challengeTaskEditedAction({ task }) {
  return {
    type: CHALLENGE_TASK_EDIT,
    task,
  }
}

export function challengeTaskEditedClearAction() {
  return {
    type: CHALLENGE_TASK_EDIT_CLEAR,
  }
}

export function challengeDayTaskCreateAction({ day, notification }) {
  return {
    type: CHALLENGE_DAY_TASK_CREATE,
    day,
    notification,
  }
}

export function challengeDayTaskCreateClearAction() {
  return {
    type: CHALLENGE_DAY_TASK_CREATE_CLEAR,
  }
}

export function challengeTaskChangeAction({ task }) {
  return {
    type: CHALLENGE_TASK_CHANGE,
    task,
  }
}

export function challengeTaskDaysCollapseToggleAction({ day }) {
  return {
    type: CHALLENGE_TASK_DAYS_COLLAPSE_TOGGLE,
    day,
  }
}

export function challengeTaskAnswerAddAction({ answer }) {
  return {
    type: CHALLENGE_TASK_ANSWER_ADD,
    answer,
  }
}

export function challengeTaskAnswerRemoveAction({ index }) {
  return {
    type: CHALLENGE_TASK_ANSWER_REMOVE,
    index,
  }
}

export function challengeTaskAnswerCorrectIndexSetAction({ index }) {
  return {
    type: CHALLENGE_TASK_ANSWER_CORRECT_SET_INDEX,
    index,
  }
}