import { call, put, takeLatest } from "redux-saga/effects"

import Storage from "services/storage"

import {
  REFERRAL_REFRESH,
  REFERRAL_REFRESH_SUCCESS,
} from "./constants"


import {
  queryParam,
} from "services/functions"

function* referralRefreshFlow() {
		
  const qpReferral = yield call(queryParam, "referral")
  if(qpReferral !== null && !isNaN(qpReferral)) {
  	yield call(handleReferralSet, qpReferral, true)
  	return
  }

  const lsReferral = yield call(Storage.getItem, process.env.REACT_APP_REFERRAL_KEY)
  if(lsReferral !== null) {
		yield call(handleReferralSet, lsReferral)
		return
  	
  }

  yield call(Storage.removeItem, process.env.REACT_APP_REFERRAL_KEY)

}

function* handleReferralSet(referral, storage) {
	if(storage) {
	  yield Storage.setItem(process.env.REACT_APP_REFERRAL_KEY, referral, true)
  }

  yield put({type: REFERRAL_REFRESH_SUCCESS, referral})
  
}

export default function* ReferralWatcher() {
  yield call(referralRefreshFlow)
  yield takeLatest(REFERRAL_REFRESH, referralRefreshFlow)
}
