/* Dependencies */
import React from "react";
import {
	Switch,
	Route,
} from "react-router-dom"

/* Components */
import Login from "components/login"
import Register from "components/register"
import ForgotPassword from "components/forgot-password"

import {
	Row,
  Col,
} from "react-materialize"

const Auth = ({authenticated}) => (
	<Row className="app-auth">
		<Col offset="m2 l3" m={8} s={12} l={6}>
			<Switch>
		    <Route exact path="/login" component={Login}/>
		    <Route exact path="/register" component={Register}/>
		    <Route exact path="/forgot-password" component={ForgotPassword}/>
		  </Switch>
		</Col>
	</Row>
)


export default Auth