import React from "react"

import {
	gettext
} from "ttag"
import {
	TextInput,
} from "react-materialize"

export default ({task, labels, onChangeHandler, preventSubmitByEnter }) => (
  <TextInput
  	s={12}
  	m={12}
  	l={12}
  	label={gettext(labels.PointsForCompletion)}
  	className="form-control"
  	onChange={onChangeHandler}
  	data-key="points"
  	value={task.points.toString()}
  	onKeyUp={preventSubmitByEnter} />
)