/* REDUX SAGA EFFECTS */ 
import { call, put, takeLatest } from "redux-saga/effects"

import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./constants"


import {
  changePasswordApi,
} from "services/api-helpers"

function* changePasswordFlow(data) {
  let changePasswordResponse
  try {

  	changePasswordResponse = yield call(changePasswordApi, data)
    if(changePasswordResponse.httpStatus) {
    	if(changePasswordResponse.message === "Incorrect password") {
	      yield put({ type: CHANGE_PASSWORD_ERROR, errors: [{key: "Zadali ste nesprávne terajšie heslo"}] })
    	} else {
    		 yield put({ type: CHANGE_PASSWORD_ERROR, errors: [{key: changePasswordResponse.message}] })
    	}

      return
    }
    
    yield put({ type: CHANGE_PASSWORD_SUCCESS })


  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_ERROR, errors: [{key: "Network error"}] })

  } 

  return changePasswordResponse
}

export default function* ChangePasswordWatcher () {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordFlow)
}
