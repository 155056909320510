import React from "react"
import {t} from "ttag"

import {
	SUPPORTED_LOCALES,
	DEFAULT_LOCALE,
} from "./constants"

import {
	getLocale,
	setLocale,
} from "./init"

import {
	Col,
	Row,
	Select,
} from "react-materialize"

export class LanguageSwitcher extends React.Component {

	_handleOnChange = (e) => {
		setLocale(e.target.value)
	}

	render() {
		return (
			<Col
				s={this.props.compact ? 6 : 12}
				m={this.props.compact ? 4 : 12}
				offset={this.props.compact ? "s3 m4" : ""}>
				<Row>
					<Select
						s={12}
						label={t`Language`}
						onChange={this._handleOnChange}
						value={getLocale() || DEFAULT_LOCALE}>
						{SUPPORTED_LOCALES.filter(loc => typeof this.props.only === "undefined" || this.props.only.indexOf(loc) !== -1).map((locale, index) => {
							return (
								<option key={`language-option-${index}`}>{locale}</option>
							)
						})}
					</Select>
				</Row>
			</Col>
		)
	}

}