import React from "react"
import {
	t,
	jt,
	ngettext,
	msgid,
} from "ttag"
import {
	Link,
} from "react-router-dom"
import {
	Icon,
} from "react-materialize"

/* VENDOR PACKAGES */ 
import { Line } from "rc-progress"
import MdAdd from "react-ionicons/lib/MdAdd"
import moment from "moment"

import {
  userHasRole
} from "components/views/auth/functions"

import {
  // ROLE_USER,
  ROLE_USER_PAID,
} from "components/views/auth/constants"

import {
	getTaskCategoryDescriptionValue,
} from "services/functions"

const ProgramHeadingText = ({client, greeting, taskHeadingText}) => {

  if(!client || !client.user)
    return false;

  return (
    <div className="program-head-section app-form-group">
      <div className="head">{`${greeting}, ${client.user.displayName}!`}</div>
      {taskHeadingText}
    </div>
  )
}

const ProgramDescriptionHeadingText = ({client, description}) => {
	if(!client || !client.user) {
    return false;
	}


	return (
    <div className="program-head-section app-form-group">
      {description}
    </div>
  )
}

const ProgramHeader = ({client, tasks, program}) => {
	
	if(!tasks.loaded && !tasks.tasks) {
    return false
	}

	var programTaskDescription = getTaskCategoryDescriptionValue(tasks)
  if(programTaskDescription !== null) {
  	return (
			<ProgramDescriptionHeadingText {...{client, description: programTaskDescription}} />
  	)
  }
  
  const tasksTotal = tasks.tasks.length
  const tasksCompleted = tasks.tasksCompletedCount

  let greeting = t`Hi`
  let taskHeadingText = ''
  let n = tasksTotal
  let tasksTotalMarkup = (
  	<span className="highlight">{ngettext(msgid`${ n } task`, `${ n } tasks`, `${ n } tasks2`, n)}</span>
  )
  
  let programNameMarkup = (
  	<span className="highlight">{program.program.name}</span>
  )
  let tasksCompletedMarkup = (
  	<span className="highlight">{`${tasksCompleted}`} {t`of`} {`${tasksTotal}`} {t`of tasks`}</span>
  )

  if(tasksCompleted > 0) {
    if(tasksCompleted === tasksTotal) {
    	greeting = t`Great job`

    	taskHeadingText = <p>{jt`Congratulations, your daily goal of challenge ${ programNameMarkup } has accomplished!`}</p>

    } else {
      taskHeadingText = <p>{jt`You have completed ${ tasksCompletedMarkup } today to reach your daily goal of your challenge ${ programNameMarkup }.`}</p>
    }
    
  } else {
    taskHeadingText = <p>{jt`You have ${ tasksTotalMarkup } today to reach your daily goal of your challenge ${ programNameMarkup }.`}</p>
  }

  if(tasksTotal === 0) {
    return <ProgramHeadingText {...{client, greeting}}/>
  }

  return <ProgramHeadingText {...{client, greeting, taskHeadingText}} /> 

}


const ScoreboardLink = ({ranks, rank}) => {
	const rankMarkup = (
		<span className="highlight">{jt`${ rank } of ${ ranks }`}</span>
	);
	return (
		<div className="program-head-section app-form-group">
			<p>
				{jt`You are on rank ${ rankMarkup }.`}
			</p>
			<Link className="btn btn-s blue lighten-1 waves-effect" rel="noopener noreferrer" to="/scoreboard">{t`View scoreboard`.toUpperCase()}</Link>
			
		</div>
	)
}

const DaysProgressBar = ({program, currentDay}) => {
	
  if(!program) {
    return false;
  }

  const today = moment(currentDay)
  const firstDay = moment(program.firstDay)
  const dayCurrent = today.diff(firstDay, "days")
  const daysTotal = program.duration
  const n = daysTotal - dayCurrent

  return (
    <div className="program-head-section app-form-group">
      {!isNaN(dayCurrent) && (
      	<React.Fragment>
      		<div className="sub-head">
      		{ngettext(msgid`${ n } day`, `${ n } days`, `${ n } days2`, n)}</div>
    	 		<p>{t`remaining`}</p>
     		</React.Fragment>
      )}
      <Line
        percent={(dayCurrent+1)/daysTotal * 100}
        strokeWidth="3"
        strokeColor="#0195ff"
        trailWidth="3"
        trailColor="#ccc"
        strokeLinecap="round"
        />
    </div>
  )
}

function _getScoreboardRank({scoreboard, user}) {
	let ranks = 0,
			rank = 0,
			currentPoints = -1;
	for(var i=0; i < scoreboard.length; i+=1) {
		var sItem = scoreboard[i];

		if(currentPoints === -1 || currentPoints !== sItem["points"]) {
			ranks += 1;
		}

		currentPoints = sItem["points"];

		if(user.id === sItem['userId']) {
			rank = ranks;
		}
	}

	return {
		ranks,
		rank,
	};
}

export default ({client, tasks, program}) => {

  if(!client.user) {
    return false;
  }

  if(!program.program) {

  	const clientName = client.user.displayName;

    return (
      <div className="app-card-head app-card-head-3">
        <div className="program-head-section app-form-group">
          <div className="head">{t`Hi ${ clientName }!`}</div>

          <p>{t`You have no active program at the moment.`}</p>

          <div className="input-field">
	          {userHasRole(client.user, ROLE_USER_PAID) && (
		          <Link className="btn blue lighten-1 waves-effect" rel="noopener noreferrer" to="/challenges/create"><Icon left>add</Icon> {t`Create a challenge`.toUpperCase()}</Link>
	          )}
	          {false && (
		          <a className="btn blue lighten-1 waves-effect" target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_CREATE_CHALLENGE_URL}><MdAdd color="#fff"/> {t`Start your own challenge`.toUpperCase()}</a>
	          )}
	        </div>
          
        </div>
      </div>
    )
  }

  const {scoreboard} = program.program;

	return (
		<div className="app-card-head app-card-head-3">
			<ProgramHeader {...{client, tasks, program}} />
			<ScoreboardLink {..._getScoreboardRank({
				scoreboard,
				user: client.user,
			})} />
			<DaysProgressBar program={program.program} currentDay={tasks.currentDay} />
	  </div>
	)
}