import React from "react"
import {
  Row,
  Col,
  Card,
  TextInput,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"
import { t, gettext } from "ttag"

import { Redirect } from "react-router-dom"

/* COMPONENTS */
import FormErrors from "components/form-errors"
import FormActionButton from "components/form-action-button"


class ChallengesCreate extends React.Component {

  static defaultProps = {
    labels: {
      ChallengeName: `Challenge name`,
      TypeInChallengeName: `Type in challenge name...`,
      TypeInTaskPoints: `Type in task points...`,
      TypeInNumberOfDays: `Type in number of days...`,
      ChallengeDurationInDays: `Challenge duration`,
      MainFormUpdateText: `Save`,
    }
  }

  state = {
    name: "",
    duration: "",
  }

  componentWillUnmount() {
  	this.props.challengeCreateClear();
  }

  handleValueChange = (fieldName, fieldValue) => {

    switch(fieldName) {
      case "duration":
        if(isNaN(fieldValue)) {
          return
        }

        this.setState({ duration: parseInt(fieldValue, 10)})
      break;

      case "name":
        this.setState({ name: fieldValue})
      break;

      default:
      break;
    }
    
  }

  handleSubmitForm = () => {
    this.props.challengeCreateAction({
    	...this.state,
    	token: this.props.token,
    });
  }

  render() {

    const {requesting, success, errors, challengeId} = this.props.challengeCreated
    if(!requesting && success && challengeId)
      return <Redirect to={`/challenges/${challengeId}/edit`} />

    return (
      <Row>
		  	<Breadcrumbs links={[
		  		{pathname: "/challenges", text: t`My challenges`},
		  		{pathname: "/challenges/create", text: t`Create new challenge`},
		  	]}/>

				<Row>
					<Col
						s={12}
						m={12}>
						<Card
					  	className="animate fadeLeft">

					  	<Row>
			          <TextInput
			          	s={12}
			          	m={12}
			            label={gettext(this.props.labels.ChallengeName)}
			            onChange={e => this.handleValueChange("name", e.target.value)}
			            value={this.state.name.toString()}
			            placeholder={gettext(this.props.labels.TypeInChallengeName)}/>
			        </Row>

			        <Row>
			          <TextInput
			          	s={12}
			          	m={12}
			            label={gettext(this.props.labels.ChallengeDurationInDays)}
			            onChange={e => this.handleValueChange("duration", e.target.value)}
			            type="number"
			            value={this.state.duration.toString()}
			            placeholder={gettext(this.props.labels.TypeInNumberOfDays)}/>
			        </Row>

		          <FormErrors errors={errors}/>

		          <div className="app-form-group">
		            <FormActionButton
		            	className="blue lighten-1 waves-effect"
		              onClick={this.handleSubmitForm}
		              text={gettext(this.props.labels.MainFormUpdateText)}
		              inProgress={requesting}/>
		          </div>

		        </Card>
					</Col>
				</Row>

		  </Row>
    );
  }

}

export default ChallengesCreate;