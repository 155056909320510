import React from "react"
import { t } from "ttag"

import MdClose from "react-ionicons/lib/MdClose"
import MdCheckmarkCircleOutline from "react-ionicons/lib/MdCheckmarkCircleOutline"
import MdRadioButtonOff from "react-ionicons/lib/MdRadioButtonOff"

import FormInput from "components/form-input"

const OrderingIndex = [
	"A", "B", "C", "D",
]

const AnswerItem = ({answer, index, challengeTaskAnswerRemoveAction, challengeTaskAnswerCorrectIndexSetAction}) => (

	<div key={"answer-" + index} className="clear">

		<span className={answer.correct ? "text-success" : ""}>{OrderingIndex[index]}. {answer.name}</span>

		<button title={t`Remove answer`} className="btn btn-sm btn-default pull-right"><MdClose fontSize="16px" onClick={() => challengeTaskAnswerRemoveAction({index})}/></button>

		<button title={answer.correct ? t`Correct answer` : t`Set correct answer`} className={"btn btn-sm pull-right" + (answer.correct ? " text-success": "")}>
			{answer.correct 
			? <MdCheckmarkCircleOutline fontSize="16px" color="#28a745" onClick={() => challengeTaskAnswerCorrectIndexSetAction({index})}/>
			: <MdRadioButtonOff fontSize="16px" color="#000" onClick={() => challengeTaskAnswerCorrectIndexSetAction({index})}/>}
		</button>

	</div>
)

export default class InputTaskAnswers extends React.Component {

	static defaultProps = {
		labels: {
			Answer: t`Answer`,
			TypeInAnswer: t`Type in answer and enter`,
		}
	}

	state = {
		value: ""
	}

	handleChallengTaskAnswerAdd = (e) => {
		if(e.key === "Enter") {
			this.props.challengeTaskAnswerAddAction({answer: {name: this.state.value}})
			this.setState({value: ""})
		}
  	
	}

	render() {

		const {task, labels, challengeTaskAnswerRemoveAction, challengeTaskAnswerCorrectIndexSetAction } = this.props

		return (
			<div className="col-md-10">

				{
					task.answers && task.answers.map((answer, index) =>
						<AnswerItem key={index} {...{answer, index, challengeTaskAnswerRemoveAction, challengeTaskAnswerCorrectIndexSetAction}} />
					)
				}

				{
					(!task.answers || task.answers.length < 4) 
					&& <FormInput
		      onChange={e => this.setState({value: e.target.value})}
		      onKeyPress={this.handleChallengTaskAnswerAdd}
		      value={this.state.value}
		      placeholder={labels.TypeInAnswer}/>
		    }
			</div>
		)
	}
}