/* REDUX */
import { connect } from "react-redux"

import {
	challengeTaskAnswerAddAction,
	challengeTaskAnswerRemoveAction,
	challengeTaskAnswerCorrectIndexSetAction,
} from "components/challenges/tasks/actions"

/* THE COMPONENT */
import InputTaskAnswers from "./component" 

const mapStateToProps = state => ({
  challengeTaskEdited: state.challengeTaskEdited,
})

const actions = {
	challengeTaskAnswerAddAction,
	challengeTaskAnswerRemoveAction,
	challengeTaskAnswerCorrectIndexSetAction,
}

export default connect(mapStateToProps, actions)(InputTaskAnswers)