import React from "react"
import { t } from "ttag"
import {
	Card,
	Row,
	Col,
	TextInput,
} from "react-materialize"
import {
	Link,
} from "react-router-dom"

import {
	searchFilterItems,
} from "services/functions"

export default class Users extends React.PureComponent {

	searchTimeout = null
	
	componentDidMount() {
		const {match, token} = this.props
		this.props.challengeStatsUsersRequestAction({token, challengeId: match.params.challengeId})
	}

	componentWillUnmount() {
		// this.props.challengeStatsUsersClearAction()
	}

	_handleChangeText = (value) => {

		if(this.searchTimeout) {
			clearTimeout(this.searchTimeout)
		}

		const {challengeStatsUsersFilterSet} = this.props
		this.searchTimeout = setTimeout(() => {
			challengeStatsUsersFilterSet({q: value})
		}, 400)
	}

	render() {

		const {users, q} = this.props.challengeStatsUsers
		const {match, max} = this.props
		return (
			<Card
		  	className="subscriber-list-card animate fadeRight"
		    title={t`Users who accepted this challenge`}>

		    <Row>
      		<TextInput
      			m={12}
      			placeholder={t`Look up the user by his name or email...`}
      			onChange={(e) => {this._handleChangeText(e.target.value)}}/>
        </Row>

		    {!!users.length && (
			    <React.Fragment>
			      <Row>
			        <table className="highlight striped">
			          <thead>
			            <tr>
			              <th>{t`Name`}</th>
			              <th>{t`Email`}</th>
			            </tr>
			          </thead>
			          <tbody>
			          	{searchFilterItems({
				          		q,
				          		items: users,
				          		searchKeys: ["displayName", "email"]
				          	})
			          		.filter((user, key) => (typeof max === "undefined" || key <= max - 1) ) 
			          		.map((user, key) => (
			          			<tr key={key}>
					              <td>
					              	<Link
					              		to={`/challenges/${match.params.challengeId}/tasks/${user.id}`}>
					              		{user.displayName}
					              	</Link>
					              </td>
					              <td>{user.email}</td>
					            </tr>
					          ))}
			          </tbody>
			        </table>
			      </Row>
			      {typeof max !== "undefined" && (
				      <Row>
				      	<Col>
				      		<a className="more-link" href={`/challenges/${match.params.challengeId}/users`}>{t`More`}</a>
				      	</Col>
				      </Row>
			      )}
			    </React.Fragment>
		    )}

		  </Card>
		)
	}
}

