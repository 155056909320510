import React from "react"

import {
  Button,
  Icon,
} from "react-materialize"

const FormActionButton = ({onClick, className, waves, text, inProgress, success, successText, size, style, disabled, icon, floating, tooltip}) => (
	<Button
		tooltip={tooltip || ""}
		floating={floating}
		disabled={disabled || false}
		className={className || ""}
		onClick={onClick}
    waves={waves || "light"}
    style={style || {}}>
		{inProgress ? <Icon className="spin">refresh</Icon> : (success ? successText : text)}
		{icon && <Icon left>{icon}</Icon>}
	</Button>
)

export default FormActionButton