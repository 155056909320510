import { call, put, takeLatest, delay } from "redux-saga/effects"

/* NAVIGATION */
import history from "client/history" 

import {
  CHALLENGE_CREATE_REQUEST,
  CHALLENGE_CREATE_SUCCESS,
  CHALLENGE_CREATE_ERROR,
  CHALLENGE_CREATE_CLEAR,

  CHALLENGE_GET_REQUEST,
  CHALLENGE_GET_SUCCESS,
  CHALLENGE_GET_ERROR,

  CHALLENGE_UPDATE_REQUEST,
  CHALLENGE_UPDATE_SUCCESS,
  CHALLENGE_UPDATE_ERROR,

  CHALLENGE_DELETE_REQUEST,
  CHALLENGE_DELETE_ERROR,

  CHALLENGE_ACTIVATE_TOGGLE_REQUEST,
  CHALLENGE_ACTIVATE_TOGGLE_SUCCESS,
  CHALLENGE_ACTIVATE_TOGGLE_ERROR,

  CHALLENGE_TASKS_GET_REQUEST,
  CHALLENGE_TASKS_GET_SUCCESS,
  CHALLENGE_TASKS_GET_ERROR,

  CHALLENGE_TASKS_CREATE_REQUEST,
  CHALLENGE_TASKS_CREATE_ERROR,

  CHALLENGE_TASKS_UPDATE_REQUEST,
  CHALLENGE_TASKS_UPDATE_ERROR,

  CHALLENGE_TASKS_DELETE_REQUEST,
  CHALLENGE_TASKS_DELETE_ERROR,

  CHALLENGES_GET_REQUEST,
  CHALLENGES_GET_SUCCESS,
  CHALLENGES_GET_ERROR,

  CHALLENGE_ACCEPT_REQUEST,
  CHALLENGE_ACCEPT_SUCCESS,
  CHALLENGE_ACCEPT_ERROR,

} from "./constants"

import {
  challengeCreateApi,
  challengeGetApi,
  challengeUpdateApi,
  challengeDeleteApi,
  challengeActivateToggleApi,
  challengeTasksGetApi,
  challengeTasksCreateApi,
  challengeTasksUpdateApi,
  challengeTasksDeleteApi,
  challengesGetApi,
  challengeAcceptApi,
  challengeStatsScoreboardGetApi,
} from "services/api-helpers"

import {
  currentProgramRequestFlow,
} from "components/daily-tasks/sagas"

function* challengeCreateFlow (data) {
  let challenge
  try {
    challenge = yield call(challengeCreateApi, data)
    if("undefined" === typeof challenge.id) {
      yield put({ type: CHALLENGE_CREATE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield put({ type: CHALLENGE_CREATE_SUCCESS, challengeId: challenge.id })
    yield put({ type: CHALLENGE_CREATE_CLEAR})

  } catch (error) {
    yield put({ type: CHALLENGE_CREATE_ERROR, errors: [{key: "Network error"}] })

  }

  return challenge
}

function* challengeGetFlow (data) {
  let challenge
  let challengeScoreboard
  try {
    challenge = yield call(challengeGetApi, data)
    if("undefined" === typeof challenge.id) {
      yield put({ type: CHALLENGE_GET_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    challengeScoreboard = yield challengeStatsScoreboardGetApi(data)
    if("undefined" === typeof challengeScoreboard.httpStatus) {
	    yield put({ type: CHALLENGE_GET_SUCCESS, challenge: {
	    	...challenge,
	    	scoreboard: challengeScoreboard,
	    }})
	    return challenge;
    }

    yield put({ type: CHALLENGE_GET_SUCCESS, challenge})

  } catch (error) {
    yield put({ type: CHALLENGE_GET_ERROR, errors: [{key: "Network error"}] })

  }

  return challenge
}

function* challengeUpdateFlow (data) {
  let challenge
  try {
    challenge = yield call(challengeUpdateApi, data)
    if("undefined" === typeof challenge.id) {
      yield put({ type: CHALLENGE_UPDATE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield put({ type: CHALLENGE_UPDATE_SUCCESS})

  } catch (error) {
    yield put({ type: CHALLENGE_UPDATE_ERROR, errors: [{key: "Network error"}] })

  }

  return challenge
}

function* challengeDeleteFlow (data) {
  let response
  try {
    response = yield call(challengeDeleteApi, data)
    if(!response.ok) {
      yield put({ type: CHALLENGE_DELETE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield call(challengesGetFlow, data)
    

  } catch (error) {
    yield put({ type: CHALLENGE_DELETE_ERROR, errors: [{key: "Network error"}] })

  }

  return response
}

function* challengeActivateToggleFlow (data) {
  let challenge
  try {
    challenge = yield call(challengeActivateToggleApi, data)
    if("undefined" === typeof challenge.id) {
      yield put({ type: CHALLENGE_ACTIVATE_TOGGLE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield put({ type: CHALLENGE_ACTIVATE_TOGGLE_SUCCESS, challenge})

  } catch (error) {
    yield put({ type: CHALLENGE_ACTIVATE_TOGGLE_ERROR, errors: [{key: "Network error"}] })

  }

  return challenge
}

function* challengeTasksGetFlow (data) {
  let tasks
  try {
    tasks = yield call(challengeTasksGetApi, data)
    if("undefined" === typeof tasks) {
      yield put({ type: CHALLENGE_TASKS_GET_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield put({ type: CHALLENGE_TASKS_GET_SUCCESS, tasks })

  } catch (error) {
    yield put({ type: CHALLENGE_TASKS_GET_ERROR, errors: [{key: "Network error"}] })

  }

  return tasks
}

function* challengeTasksCreateFlow (data) {
  let task

  try {
    task = yield call(challengeTasksCreateApi, data)
    if("undefined" === typeof task.id) {
      yield put({ type: CHALLENGE_TASKS_CREATE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield call(challengeTasksGetFlow, data)
    if(typeof data.cb !== "undefined")
      yield call(data.cb)

  } catch (error) {
    yield put({ type: CHALLENGE_TASKS_CREATE_ERROR, errors: [{key: "Network error"}] })

  }

  return task
}

function* challengeTasksUpdateFlow (data) {
  let task
  try {
    task = yield call(challengeTasksUpdateApi, data)
    if("undefined" === typeof task) {
      yield put({ type: CHALLENGE_TASKS_UPDATE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield call(challengeTasksGetFlow, data)
    if(typeof data.cb !== "undefined")
      yield call(data.cb)

  } catch (error) {
    yield put({ type: CHALLENGE_TASKS_UPDATE_ERROR, errors: [{key: "Network error"}] })

  }

  return task
}

function* challengeTasksDeleteFlow (data) {
  let response
  try {
    response = yield call(challengeTasksDeleteApi, data)
    if(!response.ok) {
      yield put({ type: CHALLENGE_TASKS_DELETE_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield call(challengeTasksGetFlow, data)
    

  } catch (error) {
    yield put({ type: CHALLENGE_TASKS_DELETE_ERROR, errors: [{key: "Network error"}] })

  }

  return response
}

function* challengesGetFlow (data) {
  let challenges
  try {
    challenges = yield call(challengesGetApi, data)
    if("undefined" === typeof challenges) {
      yield put({ type: CHALLENGES_GET_ERROR, errors: [{key: "Invalid payload"}] })
      return
    }

    yield put({ type: CHALLENGES_GET_SUCCESS, challenges })

  } catch (error) {
    yield put({ type: CHALLENGES_GET_ERROR, errors: [{key: "Network error"}] })

  }

  return challenges
}

function* challengeAcceptFlow(data) {
  let response
  try {
  	console.log(data)
    response = yield call(challengeAcceptApi, data)
    if("undefined" === typeof response.program) {

      if(response.message === "User currently has an active program") {
        yield put({ type: CHALLENGE_ACCEPT_SUCCESS, messages: [{key: "You already have accepted a challenge. Redirecting you to your daily tasks..."}] })

      }
      
    } else {
      yield put({ type: CHALLENGE_ACCEPT_SUCCESS, messages: [{key: "Challenge accepted! Redirecting you to your daily tasks..."}] })
    }
    
    yield call(currentProgramRequestFlow, data)
    yield delay(1500)
    yield history.push('/')

  } catch (error) {
    yield put({ type: CHALLENGE_ACCEPT_ERROR, errors: [{key: "Network error."}] })

  }

  return response
}

export default function* ChallengesWatcher() {
  yield takeLatest(CHALLENGE_CREATE_REQUEST, challengeCreateFlow)
  yield takeLatest(CHALLENGE_GET_REQUEST, challengeGetFlow)
  yield takeLatest(CHALLENGE_UPDATE_REQUEST, challengeUpdateFlow)
  yield takeLatest(CHALLENGE_DELETE_REQUEST, challengeDeleteFlow)
  yield takeLatest(CHALLENGE_ACTIVATE_TOGGLE_REQUEST, challengeActivateToggleFlow)
  yield takeLatest(CHALLENGE_TASKS_GET_REQUEST, challengeTasksGetFlow)
  yield takeLatest(CHALLENGE_TASKS_CREATE_REQUEST, challengeTasksCreateFlow)
  yield takeLatest(CHALLENGE_TASKS_UPDATE_REQUEST, challengeTasksUpdateFlow)
  yield takeLatest(CHALLENGE_TASKS_DELETE_REQUEST, challengeTasksDeleteFlow)
  yield takeLatest(CHALLENGES_GET_REQUEST, challengesGetFlow)
  yield takeLatest(CHALLENGE_ACCEPT_REQUEST, challengeAcceptFlow)
}
