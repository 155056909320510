import { call, put, takeLatest } from "redux-saga/effects"
import { t } from "ttag"

import Storage from "services/storage"

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_CANCEL,
  LOGOUT,
  FACEBOOK_LOGIN_REQUEST,
} from "./constants"

import {
  setClient,
  unsetClient,
} from "client/actions"

import {
	currentProgramAction,
  currentProgramClearAction,
  dailyTasksClearAction
} from "components/daily-tasks/actions"

import {
  loginApi,
  facebookLoginApi,
} from "services/api-helpers"

import {
  facebookLogin,
  getFacebookAccessToken,
} from "services/external-api-helpers"

import {
	setLocale,
} from "components/locale/init"

function* loginRefresh() {

  const clientDataParsed = yield Storage.getItem(process.env.REACT_APP_CLIENT_AUTH_KEY)
  if(clientDataParsed === null)
    return

  yield put(setClient(clientDataParsed))

}

function* logout() {
  
  // remove our token from storage
  yield Storage.removeItem(process.env.REACT_APP_CLIENT_AUTH_KEY)

  // dispatch CLIENT_UNSET action
  yield put(unsetClient())
  yield put(currentProgramClearAction())
  yield put(dailyTasksClearAction())
}

function* loginFlow (data) {
  let response
  try {
    response = yield call(loginApi, data)
    if("undefined" === typeof response.authToken) {
      yield put({ type: LOGIN_ERROR, errors: [{key: t`Invalid credentials`}] })
      return
    }

    yield call(handleLoginSuccess, {response, callback: data.cb})

  } catch (error) {
    yield put({ type: LOGIN_ERROR, errors: [{key: t`Network error`}] })

  }

  return response
}

function* facebookLoginFlow (data) {
  let facebookLoginResponse

  if(process.env.REACT_APP_APP_MODE !== "production") {
    yield put({ type: LOGIN_CANCEL })
    return facebookLoginResponse
  }

  try {
    facebookLoginResponse = yield call(facebookLogin)
    if(!facebookLoginResponse.isCancelled) {

      let { accessToken } = yield call(getFacebookAccessToken)
      if("undefined" !== accessToken && accessToken) {

        let apiFacebookLoginResponse = yield call(facebookLoginApi, {accessToken})
        if("undefined" === typeof apiFacebookLoginResponse.authToken) {
          
          yield put({ type: LOGIN_ERROR, errors: [{key: t`Oops, something went wrong`}] })
          return facebookLoginResponse
        }

        yield call(handleLoginSuccess, {response: apiFacebookLoginResponse, callback: data.cb})
        
      }
    } else {
      yield put({ type: LOGIN_CANCEL })
    }
  } catch (error) {
    yield put({ type: LOGIN_ERROR, errors: [{key: t`Network error`}] })
  }


  return facebookLoginResponse
}

function* handleLoginSuccess({response, callback}) {
  yield put(setClient(response))
  yield Storage.setItem(process.env.REACT_APP_CLIENT_AUTH_KEY, response)
  yield put({ type: LOGIN_SUCCESS })
	yield put(currentProgramAction({token: response.authToken}))

  if(typeof callback !== "undefined") {
    yield call(callback)
  }

  setLocale(response.user.language);
}

export default function* LoginWatcher () {
  yield call(loginRefresh)
  yield takeLatest(LOGIN_REQUEST, loginFlow)
  yield takeLatest(LOGOUT, logout)
  yield takeLatest(FACEBOOK_LOGIN_REQUEST, facebookLoginFlow)
}
