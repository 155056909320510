import React from "react"
import { gettext } from "ttag"
import {
	Button,
	Icon,
} from "react-materialize"

import TaskForm from "components/challenges/tasks/task-form"
import NotificationForm from "components/challenges/tasks/notification-form"
import TaskTypeOptions from "components/challenges/tasks/task-type-options"

const labels = {
  CreateTask: `Create task`,
  CreateNotification: `Create notification`,
}

export const ChallengeDayTasksCreate = ({
	day,
	handleNewTaskValueChanged,
	handleCreateTask,
	challengeDayTaskCreateClearAction,
	clearTaskNameEdited,
	parseTaskType,
  challengeDayTaskCreateAction,
  challengeTaskEdited,
}) => {

  const { task } = challengeTaskEdited
  if(task.id || task.dayStart !== day)
    return (
      <li key="create-task-form">
      	<Button
      		className="blue lighten-1 create-task-toggle mr5 mb5"
      		waves="light"
      		onClick={() => challengeDayTaskCreateAction({day})}>
      		<Icon left>add</Icon> {gettext(labels.CreateTask)}
      	</Button>
      	<Button
      		className="blue lighten-1 create-task-toggle mb5"
      		waves="light"
      		onClick={() => challengeDayTaskCreateAction({day, notification: true})}>
      		<Icon left>add</Icon> {gettext(labels.CreateNotification)}
      	</Button>
      </li>
    )

 	if(task.category === "NOTIFY_CHAT") {
  	return (
      <li key={"task-" + day + "-" + challengeTaskEdited.task.id} className="task-edited">
        <NotificationForm
          task={challengeTaskEdited.task}
          onChangeHandler={handleNewTaskValueChanged}
          onSubmitHandler={handleCreateTask}
          clearFormActionHandler={challengeDayTaskCreateClearAction}
          clearTaskNameEdited={clearTaskNameEdited}/>
      </li>
    )
	}

  return (
    <li key="create-task-form" className="task-edited">
      <TaskForm
        task={task}
        onChangeHandler={handleNewTaskValueChanged}
        onSubmitHandler={handleCreateTask}
        clearFormActionHandler={challengeDayTaskCreateClearAction}
        clearTaskNameEdited={clearTaskNameEdited}
        labels={labels}
        taskTypeOptions={<TaskTypeOptions
                          task={task}
                          parseTaskType={parseTaskType}
                          taskType="newTask"
                          labels={labels}/>} />
    </li>
  )
}