export const CURRENT_PROGRAM_REQUEST = "CURRENT_PROGRAM_REQUEST"
export const CURRENT_PROGRAM_SUCCESS = "CURRENT_PROGRAM_SUCCESS"
export const CURRENT_PROGRAM_ERROR = "CURRENT_PROGRAM_ERROR"
export const CURRENT_PROGRAM_CLEAR = "CURRENT_PROGRAM_CLEAR"
export const DAILY_TASKS_REQUEST = "DAILY_TASKS_REQUEST"
export const DAILY_TASKS_SUCCESS = "DAILY_TASKS_SUCCESS"
export const DAILY_TASKS_ERROR = "DAILY_TASKS_ERROR"
export const DAILY_TASKS_CLEAR = "DAILY_TASKS_CLEAR"
export const TASK_TOGGLE_REQUEST = "TASK_TOGGLE_REQUEST"
export const TASK_TOGGLED = "TASK_TOGGLED"
export const TASK_CHANGED = "TASK_CHANGED"
export const TASK_VIDEO_PLAYING_TOGGLE = "TASK_VIDEO_PLAYING_TOGGLE"
export const TASK_VIDEO_PLAYBACK_STARTED = "TASK_VIDEO_PLAYBACK_STARTED"
export const TASK_SHARE = "TASK_SHARE"
export const DAY_MOVE_BACK = "DAY_MOVE_BACK"
export const DAY_MOVE_FORWARD = "DAY_MOVE_FORWARD"
