import React from "react"
import {
  Row,
  Col,
  Card,
  Icon,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"
import { t } from "ttag"

import {
	Link,
} from "react-router-dom"

export default ({user}) => (
  <Row>

  	<Breadcrumbs links={[
  		{pathname: "/account", text: t`My account`},
  	]}/>

		<Row>
			<Col s={12} m={5}>
				<Card
			  	className="animate fadeLeft"
			    title={t`My account`}>

			    {false && <div className="align-top-right align-padded">
						<a title={t`Edit`} className="blue-text lighten-1" href="/account/change-profile">
				    	<Icon tiny>edit</Icon>
				    </a>
			    </div>}
			    
			    <div className="account-group">
			    	<label>{t`Name`}</label>
			    	<div className="account-item flex-spread">{user.displayName}</div>
			    </div>

			    <div className="account-group">
			    	<label>{t`Email`}</label>
			    	<div className="account-item">{`${user.email}`}</div>
			    </div>

			    {user.phoneNumber && (
			    	<div className="account-group">
				    	<label>{t`Phone number`}</label>
				    	<div className="account-item">{`${user.phoneNumber}`}</div>
				    </div>
				  )}

			    <div className="input-group"></div>

			    <div className="account-group">
			    	<label>{t`Password`}</label>
			    	<div className="account-item flex-spread">
			    		<span>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</span>
			    		<Link title={t`Change password`} className="blue-text lighten-1" to="/account/change-password">
					    	<Icon tiny>edit</Icon>
					    </Link>
				    </div>
				    
				  </div>

			  </Card>
			</Col>
		</Row>

  </Row>
)