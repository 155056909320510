import {
  CHALLENGE_STATS_DAYS_REQUEST,
  CHALLENGE_STATS_DAYS_CLEAR,
} from "./constants"

export function challengeStatsDaysRequestAction({ token, challengeId, callback }) {
  return {
    type: CHALLENGE_STATS_DAYS_REQUEST,
    token,
    challengeId,
    callback,
  }
}

export function challengeStatsDaysClearAction() {
  return {
    type: CHALLENGE_STATS_DAYS_CLEAR,
  }
}