import React from "react"
import { t } from "ttag"
import {
	Card,
	Row,
	Col,
} from "react-materialize"

import Chartist from "chartist"
import ChartistGraph from "react-chartist"

const options = {
	fullWidth: true,
	showPoint: false,
  axisY: {
  	showLabel: true,
    showGrid: false,
    low: 0,
    scaleMinSpace: 30
  },
  axisX: {
    showLabel: true,
    showGrid: true,
  },
  lineSmooth: Chartist.Interpolation.simple({
    divisor: 2
  }),
}

export default class Days extends React.PureComponent {

	componentDidMount() {
		const {match, token} = this.props
		const {loaded} = this.props.challengeStatsDays
		if(!loaded) {
			this.props.challengeStatsDaysRequestAction({token, challengeId: match.params.challengeId})
		}
	}

	componentWillUnmount() {
		this.props.challengeStatsDaysClearAction()
	}

	getStats = () => {
		const {loaded, requesting, days} = this.props.challengeStatsDays
		if(requesting || !loaded || !days.length) {
			return false
		}

		return days
	}

	getData = (days) => {
		return {
		  labels: days.map(day => { return day.dayNumber.toString()} ),
		  series: [
		    days.map(day => day.totalTasks),
		    days.map(day => day.accomplishedTasks),
		  ]
		}
	}

	getOptions = (currentStat) => {
		return {
			donut: true,
		  donutWidth: 8,
		  showLabel: false,
		  plugins: [
		    // Chartist.plugins.tooltip({ class: "current-balance-tooltip", appendToBody: true }),
		    Chartist.plugins.fillDonut({
					items: [
					  {
				      content: `<div class="center"><h5>${currentStat.wallet || 0},-</h5><p class="small">` + t`Balance` + ` (EUR)</p></div>`
					  }
					]
		    })
		  ]
		}
	}

	render() {

		const stats = this.getStats()
		if(!stats) {
			return false;
		}

		return (
			<Card
		  	className="animate fadeRight"
		    title={t`Tasks completion`}>

		    <Row className="orders-legend icons">
		    	<Col className="series-b" l={12} m={12} s={12}>
		    		{t`Total tasks`}
		    	</Col>
		    	<Col className="series-a" l={12} m={12} s={12}>
		    		{t`Completed tasks`}
		    	</Col>
		    </Row>

		    <ChartistGraph className="ct-production" data={this.getData(stats)} options={options} type="Line" />
		  </Card>
		)
	}
} 
