import {
  CHALLENGE_STATS_DAYS_REQUEST,
  CHALLENGE_STATS_DAYS_SUCCESS,
  CHALLENGE_STATS_DAYS_ERROR,
  CHALLENGE_STATS_DAYS_CLEAR,
} from "./constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
  loaded: false,
  days: [],
}

export default function(state = initialState, action) {
  switch (action.type) {

    case CHALLENGE_STATS_DAYS_REQUEST:
      return {
        ...state,
        requesting: true,
      }

    case CHALLENGE_STATS_DAYS_SUCCESS:
      return {
        ...state,
        requesting: false,
        loaded: true,
        success: true,
        days: [
        	...action.days,
        ],
      }

    case CHALLENGE_STATS_DAYS_ERROR:
      return {
        ...state,
        errors: [
        	...action.errors,
        ],
        success: false,
      }

    case CHALLENGE_STATS_DAYS_CLEAR:
      return {
        ...initialState,
      }

    default:
      return state
  }
}