import {
  CURRENT_PROGRAM_REQUEST,
  CURRENT_PROGRAM_CLEAR,
  DAILY_TASKS_CLEAR,
  TASK_TOGGLE_REQUEST,
  TASK_CHANGED,
  TASK_VIDEO_PLAYING_TOGGLE,
  TASK_VIDEO_PLAYBACK_STARTED,
  TASK_SHARE,
  DAY_MOVE_BACK,
  DAY_MOVE_FORWARD,
} from "./constants"

export function currentProgramAction({token}) {
  return {
    type: CURRENT_PROGRAM_REQUEST,
    token: token,
  }
}

export function currentProgramClearAction() {
  return {
    type: CURRENT_PROGRAM_CLEAR,
  }
}

export function dailyTasksClearAction() {
  return {
    type: DAILY_TASKS_CLEAR,
  }
}

export function taskToggleAction({ taskTogglePayload }) {
  return {
    type: TASK_TOGGLE_REQUEST,
    taskTogglePayload
  }
}

export function taskInputChangeAction({ task }) {
  return {
    type: TASK_CHANGED,
    task
  }
}

export function taskVideoPlayingToggleAction({ task }) {
  return {
    type: TASK_VIDEO_PLAYING_TOGGLE,
    task
  }
}

export function taskVideoPlaybackStartedAction({ task }) {
  return {
    type: TASK_VIDEO_PLAYBACK_STARTED,
    task
  }
}

export function taskShareAction({ shareLinkContent, callback }) {
  return {
    type: TASK_SHARE,
    shareLinkContent,
    callback,
  }
}

export function dayMoveBackAction({program}) {
  return {
    type: DAY_MOVE_BACK,
    program,
  }
}

export function dayMoveForwardAction({program}) {
  return {
    type: DAY_MOVE_FORWARD,
    program,
  }
}