import {
  DAILY_TASKS_REQUEST,
  DAILY_TASKS_SUCCESS,
  DAILY_TASKS_ERROR,
  DAILY_TASKS_CLEAR,

  TASK_TOGGLED,
  TASK_CHANGED,
  TASK_VIDEO_PLAYING_TOGGLE,
  TASK_VIDEO_PLAYBACK_STARTED,

  DAY_MOVE_BACK,
  DAY_MOVE_FORWARD,
} from "../constants"

import {
  parseShortcode,
} from "services/functions"

import moment from "moment"

const initialStateTasks = {
  tasks: null,
  tasksCompletedCount: 0,
  tasksUptill: null,
  currentDay: null,
  previousTasksDay: false,
  nextTasksDay: false,
  requesting: false,
  success: false,
  loaded: false,
  errors: [],
}

const tasks = function tasksReducer(state = initialStateTasks, action) {
  switch (action.type) {
    case DAILY_TASKS_REQUEST:
      return { ...state,
        tasks: null,
        tasksCompletedCount: 0,
        requesting: true,
        errors: [].concat(state.errors),
      }

    case DAILY_TASKS_SUCCESS:
      return { ...state,
        tasks: action.tasks.map( task => Object.assign({}, task, parseShortcode(task.name)) ),
        tasksCompletedCount: action.tasks.filter(task => task.completed).length,
        tasksUptill: action.tasksUptill.map( task => Object.assign({}, task, parseShortcode(task.name)) ),
        currentDay: moment().format('YYYY-MM-DD'),
        requesting: false,
        success: true,
        loaded: true,
        errors: [],
      }

    case DAY_MOVE_BACK:
      var oldBackDay = moment(state.currentDay)
      oldBackDay.subtract(1, 'days')
      const backDay = oldBackDay.format('YYYY-MM-DD')
      const backDayTasks = state.tasksUptill.filter(task => task.day === backDay)
      return { ...state,
        currentDay: backDay,
        tasks: backDayTasks,
        tasksCompletedCount: backDayTasks.filter(task => task.completed).length,
      }

    case DAY_MOVE_FORWARD:
      var oldForwardDay = moment(state.currentDay)
      oldForwardDay.add(1, 'days')
      const forwardDay = oldForwardDay.format('YYYY-MM-DD')
      const forwardDayTasks = state.tasksUptill.filter(task => task.day === forwardDay)
      return { ...state,
        currentDay: forwardDay,
        tasks: forwardDayTasks,
        tasksCompletedCount: forwardDayTasks.filter(task => task.completed).length,
      }

    case DAILY_TASKS_ERROR:
      return { ...state,
        tasks: null,
        tasksCompletedCount: 0,
        requesting: false,
        loaded: true,
        errors: [].concat(action.errors),
      }

    case DAILY_TASKS_CLEAR:
      return { ...state,
        tasks: null,
        tasksCompletedCount: 0,
        tasksUptill: null,
        currentDay: moment().format('YYYY-MM-DD'),
        requesting: false,
        success: false,
        loaded: false,
        errors: [],
      }

    case TASK_TOGGLED:
    case TASK_CHANGED:
      const updatedTasks = state.tasks.map(task => {
        if(action.task.id !== task.id)
          return task

        return Object.assign({}, task, action.task, {playing: null})

      });
      const updatedTasksUptill = state.tasksUptill.map(task => {
        if(action.task.id !== task.id)
          return task

        return Object.assign({}, task, action.task, {playing: null})

      });
      return { ...state,
        tasks: updatedTasks,
        tasksCompletedCount: updatedTasks.filter(t => t.completed).length,
        tasksUptill: updatedTasksUptill,
      }

    case TASK_VIDEO_PLAYING_TOGGLE:
      const newTasks = state.tasks.map(task => {
        if(action.task.id !== task.id)
          return task

        return Object.assign({}, task, (task.playing ? {playing: null} : {playing: true}), {playback: false})

      });
      return { ...state,
        tasks: newTasks
      }

    case TASK_VIDEO_PLAYBACK_STARTED:
      const playbackUpdatedTasks = state.tasks.map(task => {
        if(action.task.id !== task.id)
          return task

        return Object.assign({}, task, {playback: true})

      });
      return { ...state,
        tasks: playbackUpdatedTasks
      }

    default:
      return state
  }
}


export default tasks