import {
  REGISTER_REQUEST,
  REGISTER_VALIDATION_ERROR,
  REGISTER_CLEAR
} from "./constants"

export function registerRequestAction({ displayName, email, password, language, cb }) {
  return {
    type: REGISTER_REQUEST,
    displayName,
    email,
    password,
    language,
    cb,
  }
}

export function registerClearAction() {
	return {
		type: REGISTER_CLEAR
	}
}

export function registerValidationErrorAction({ errors }) {
  return {
    type: REGISTER_VALIDATION_ERROR,
    errors
  }
}