import {
  CHALLENGE_STATS_USER_TASKS_REQUEST,
  CHALLENGE_STATS_USER_TASKS_SUCCESS,
  CHALLENGE_STATS_USER_TASKS_ERROR,
  CHALLENGE_STATS_USER_TASKS_CLEAR,
  CHALLENGE_STATS_USER_TASKS_FILTER_SET,
  CHALLENGE_STATS_USER_TASKS_COMPLETED_TOGGLE,
} from "./constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
  loaded: false,
  tasks: [],
  q: "",
}

export default function(state = initialState, action) {
  switch (action.type) {

    case CHALLENGE_STATS_USER_TASKS_REQUEST:
      return {
        ...state,
        requesting: true,
      }

    case CHALLENGE_STATS_USER_TASKS_SUCCESS:
      return {
        ...state,
        requesting: false,
        loaded: true,
        success: true,
        tasks: [
        	...action.tasks,
        ],
      }

    case CHALLENGE_STATS_USER_TASKS_ERROR:
      return {
        ...state,
        errors: [
        	...action.errors,
        ],
        success: false,
      }

    case CHALLENGE_STATS_USER_TASKS_CLEAR:
      return {
        ...initialState,
      }

    case CHALLENGE_STATS_USER_TASKS_FILTER_SET:
    	return {
    		...state,
    		q: action.q,
    	}

    case CHALLENGE_STATS_USER_TASKS_COMPLETED_TOGGLE:
    	return {
    		...state,
    		completed: action.completed,
    	}

    default:
      return {
      	...state,
      }
  }
}