import React from "react"
import {
	Button,
	Icon,
	Row,
	Col,
} from "react-materialize"

import TaskForm from "components/challenges/tasks/task-form"
import NotificationForm from "components/challenges/tasks/notification-form"
import TaskTypeOptions from "components/challenges/tasks/task-type-options"
import TaskTypeView from "components/challenges/tasks/task-type-view"

import {
  matchShortcode,
} from "services/functions"

export const ChallengeDayTasksEdit = ({
	labels,
	task,
	challenge,
	day,
	taskInputTypes,
	handleNewTaskValueChanged,
	handleUpdateTask,
	handleDeleteTask,
	challengeTaskEditedClearAction,
	clearTaskNameEdited,
	parseTaskType,
  challengeTaskEdited,
  challengeTaskEditedAction,
}) => {

  if(challengeTaskEdited.task.id === task.id) {

  	if(task.category === "NOTIFY_CHAT") {
	  	return (
	      <li key={"task-" + day + "-" + challengeTaskEdited.task.id} className="task-edited">
	        <NotificationForm
	          task={challengeTaskEdited.task}
	          onChangeHandler={handleNewTaskValueChanged}
	          onSubmitHandler={handleUpdateTask}
	          clearFormActionHandler={challengeTaskEditedClearAction}
	          clearTaskNameEdited={clearTaskNameEdited}/>
	      </li>
	    )
  	}

    return (
      <li key={"task-" + day + "-" + challengeTaskEdited.task.id} className="task-edited">
        <TaskForm
          task={challengeTaskEdited.task}
          onChangeHandler={handleNewTaskValueChanged}
          onSubmitHandler={handleUpdateTask}
          clearFormActionHandler={challengeTaskEditedClearAction}
          taskInputTypes={taskInputTypes}
          clearTaskNameEdited={clearTaskNameEdited}
          taskTypeOptions={(
          	<TaskTypeOptions
	            task={challengeTaskEdited.task}
	            parseTaskType={parseTaskType}
	            taskType="newTask"/>
          )}/>
      </li>
    )
  }

  return (
    <li key={"task-" + day + "-" + task.id}>
      <div className="col-sm-5 d-pad-right nopad">
        {matchShortcode(task.name) ? <TaskTypeView task={task}/> : task.name}
      </div>
      {!challenge.active && (
      	<Row>
		      <Col 
		      	s={12}
		      	m={12}
		      	l={12}
		      	className="task-actions">
		        <Button
		        	className="blue lighten-1 mr5"
		        	onClick={() => challengeTaskEditedAction({task})}>
		        	<Icon>edit</Icon>
		        </Button>
		        <Button
		        	className="blue lighten-1"
		        	onClick={e => {
		        		e.preventDefault()
		        		handleDeleteTask(task.id)
		        	}}>
		        	<Icon>delete_forever</Icon>
		        </Button>
		      </Col>
      	</Row>
      )}
    </li>
  )
}