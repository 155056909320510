/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	taskShareAction,
} from "components/daily-tasks/actions"

/* THE COMPONENT */
import FacebookShareButton from "./component"

const mapStateToProps = state => ({
	token: state.client.token,
  program: state.program.program,
})

const actions = {
	taskShareAction,
}

export default connect(mapStateToProps, actions)(FacebookShareButton)