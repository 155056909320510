/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	currentProgramAction,
	currentProgramClearAction,
	dailyTasksClearAction,
	taskToggleAction,
	taskInputChangeAction,
	taskVideoPlayingToggleAction,
	taskVideoPlaybackStartedAction
} from "./actions"

/* THE COMPONENT */ 
import DailyTasks from "./component"

const mapStateToProps = state => ({
	client: state.client,
  program: state.program,
  tasks: state.tasks
})

const actions = {
	currentProgramAction,
	currentProgramClearAction,
	dailyTasksClearAction,
	taskToggleAction,
	taskInputChangeAction,
	taskVideoPlayingToggleAction,
	taskVideoPlaybackStartedAction
}

export default connect(mapStateToProps, actions)(DailyTasks)