import React from "react"
import { Link } from "react-router-dom"
import IosArrowRoundBack from "react-ionicons/lib/IosArrowRoundBack"

const FormBackLink = ({text, link, onClick}) => (
	<div className="app-form-meta">
    {!onClick && <Link to={link}><IosArrowRoundBack color="#007bff" />{text}</Link>}
    {onClick && <a href="/" onClick={e => {e.preventDefault(); onClick()}}><IosArrowRoundBack color="#007bff" />{text}</a>}
  </div>
)

export default FormBackLink