import { call, put, takeLatest } from "redux-saga/effects"
import { t } from "ttag"

import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
} from "./constants"


import {
  resetPasswordApi
} from "services/api-helpers"

function* forgotPasswordFlow(data) {
  let forgotPasswordResponse
  try {
    
    forgotPasswordResponse = yield call(resetPasswordApi, data)
    if(forgotPasswordResponse.status === 200) {
      yield put({ type: FORGOT_PASSWORD_SUCCESS })
      return
    }

    yield put({ type: FORGOT_PASSWORD_ERROR, errors: [{key: forgotPasswordResponse.message}] })

  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_ERROR, errors: [{key: t`Network error`}] })

  } 

  return forgotPasswordResponse
}

export default function* ForgotPasswordWatcher () {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordFlow)
}
