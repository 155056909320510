import React from "react"
import { t } from "ttag"
import {
	Card,
	Row,
	Col,
	TextInput,
} from "react-materialize"

import Breadcrumbs from "components/breadcrumbs"
import TaskTypeView from "components/challenges/tasks/task-type-view"
import LoadingSpinner from "components/loading-spinner"

import {
	searchFilterItems,
  matchShortcode,
} from "services/functions"

import moment from "moment"

export default class UserTasks extends React.PureComponent {

	searchTimeout = null
	
	componentDidMount() {
		const {match, token} = this.props

		const challenge = this._challengeLoaded()
		if(!challenge || challenge.id !== parseInt(match.params.challengeId, 10)) {
			this.props.challengeGetAction({token, challengeId: match.params.challengeId})
		}
		const users = this._challengeUsersLoaded()
		if(!users) {
			this.props.challengeStatsUsersRequestAction({token, challengeId: match.params.challengeId})
		}

		const {loaded} = this.props.challengeStatsUserTasks
		if(!loaded) {
			this.props.challengeStatsUserTasksRequestAction({
				token,
				challengeId: match.params.challengeId,
				userId: match.params.userId,
			})
		}

	}

	componentWillUnmount() {
		this.props.challengeEditedClearAction()
		this.props.challengeStatsUserTasksClearAction()
	}

  _challengeLoaded = () => {
  	const {challenge} = this.props.challengeEdited

   	if(!challenge) {
   		return false
   	}

   	return challenge
  }

	_challengeUsersLoaded = () => {
		const {users, loaded} = this.props.challengeStatsUsers

   	if(!loaded) {
   		return false
   	}

   	return users
	}

	_getChallengeUserById = (id) => {
		const users = this._challengeUsersLoaded()
		if(!users) {
			return false
		}

		const filtered = users.filter(user => user.id === parseInt(id, 10))
		if(!filtered.length) {
			return false
		}

		return filtered[0]
	}

	_handleChangeText = (value) => {

		if(this.searchTimeout) {
			clearTimeout(this.searchTimeout)
		}

		const {challengeStatsUserTasksFilterSet} = this.props
		this.searchTimeout = setTimeout(() => {
			challengeStatsUserTasksFilterSet({q: value})
		}, 400)
	}

	_filterTasks = () => {
		const {tasks, q} = this.props.challengeStatsUserTasks
		const {max} = this.props
		return searchFilterItems({
						q,
						items: tasks,
						searchKeys: ["name", "inputValue"]
					})
			    .filter((item, key) => (typeof max === "undefined" || key <= max - 1) ) 
	}

	_parseTaskDateSections = (tasks) => {
		let dateSections = {}
		tasks.map((task, key) => {
			if(typeof dateSections[task.day] === "undefined") {
				dateSections[task.day] = []
			}

			dateSections[task.day].push(task)

			return task
		})

		var dateSectionItems = []
		for(var prop in dateSections) {
			dateSectionItems.push({section: prop, tasks: dateSections[prop]})
		}

		return dateSectionItems
	}

	_renderDateTasksSection = () => {
		const filteredTasks = this._filterTasks()
		const dateSections = this._parseTaskDateSections(filteredTasks)
		return (
			<tbody>
				{dateSections.map((item, key) => {
					return [
						this._renderTaskDateSectionRow(item),
					].concat(item.tasks.map(this._renderTaskRow));
				})}
			</tbody>
		)
	}

	_renderTaskDateSectionRow = (item) => (
		<tr key={`task-day-section-${item.section}`} className="blue lighten-2 white-text">
      <td>
      	{t`Day`}: {item.section}
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
	)

	_renderTaskRow = (item, key) => (
		<tr key={`${item.id}-${key}`}>
      <td>
      	{matchShortcode(item.name) ? (
      		<TaskTypeView
      			task={item}
      			/>
	      ) : (
	      	<span>{item.name}</span>
	      )}
      </td>
      <td>{item.day}</td>
      <td>{item.completed && moment(item.completed).format("YYYY-MM-DD")}</td>
      <td>{item.inputValue && item.inputValue.trim()}</td>
    </tr>
	)

	render() {

		const {match, max} = this.props
		const challenge = this._challengeLoaded()
		const user = this._getChallengeUserById(match.params.userId)
		const {requesting, loaded} = this.props.challengeStatsUserTasks

		return (

			<Row>

				{challenge && user && (
					<Breadcrumbs links={[
						{pathname: "/challenges", text: t`My challenges`},
						{pathname: `/challenges/${challenge.id}/dashboard`, text: challenge.name},
						{pathname: `/challenges/${challenge.id}/tasks/${user.id}`, text: user.displayName},
						]}/>
				)}

				<Row>
					<Card
				  	className="subscriber-list-card animate fadeRight">

				    <Row>
		      		<TextInput
		      			m={12}
		      			placeholder={t`Look up tasks by name or answer...`}
		      			onChange={(e) => {this._handleChangeText(e.target.value)}}/>
		        </Row>

				    
				    <React.Fragment>
				      <Row>
				      	{requesting && (
	          			<LoadingSpinner/>
			          )}

			          {!requesting && loaded && (
					        <table className="striped task-days">
					          <thead>
					            <tr>
					              <th>{t`Name`}</th>
					              <th>{t`Date`}</th>
					              <th>{t`Completed`}</th>
					              <th>{t`Answer`}</th>
					            </tr>
					          </thead>
					          {this._renderDateTasksSection()}
					        </table>
			          )}
				      </Row>
				      {typeof max !== "undefined" && (
					      <Row>
					      	<Col>
					      		<a className="more-link" href={`/challenges/${challenge.id}/users`}>{t`More`}</a>
					      	</Col>
					      </Row>
				      )}
				    </React.Fragment>

				  </Card>
				</Row>
			</Row>
		)
	}
}

