import {
  CHALLENGE_TASK_EDIT,
  CHALLENGE_TASK_EDIT_CLEAR,
  CHALLENGE_DAY_TASK_CREATE,
  CHALLENGE_DAY_TASK_CREATE_CLEAR,
  CHALLENGE_TASK_CHANGE,
  CHALLENGE_TASK_DAYS_COLLAPSE_TOGGLE,
  CHALLENGE_TASK_ANSWER_ADD,
  CHALLENGE_TASK_ANSWER_REMOVE,
  CHALLENGE_TASK_ANSWER_CORRECT_SET_INDEX,
} from "components/challenges/tasks/constants"

const initialState = {
  task: {
    answers: [],
    dayStart: null,
    dayEnd: null,
    inputType: "NONE",
    name: "",
    category: "MORNING",
    points: 0,
    location: null,
    completeTime: "8:00",
  },
  collapsedDayIndexes: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHALLENGE_TASK_EDIT:
      return {
        ...state,
        task: action.task,
      }

    case CHALLENGE_TASK_CHANGE:
      return {
        ...state,
        task: {
        	...state.task,
        	...action.task,
        },
      }

    case CHALLENGE_DAY_TASK_CREATE:
      return {
        ...initialState,
        task: {
        	...initialState.task,
        	dayStart: action.day,
        	dayEnd: action.day,
        	category: action.notification ? "NOTIFY_CHAT" : initialState.task.category,
        	name: action.notification ? "Notification" : action.name,
        },
      }

    case CHALLENGE_TASK_EDIT_CLEAR: // fall thru
    case CHALLENGE_DAY_TASK_CREATE_CLEAR:
      return {
      	...initialState,
      }

    case CHALLENGE_TASK_DAYS_COLLAPSE_TOGGLE:
      if(state.collapsedDayIndexes.indexOf(action.day) !== -1) {
        return {
          ...state,
          collapsedDayIndexes: state.collapsedDayIndexes.filter(day => day !== action.day),
        }
      } else {
        return {
          ...state,
          collapsedDayIndexes: state.collapsedDayIndexes.concat([action.day]),
        }
        
      }

    case CHALLENGE_TASK_ANSWER_ADD:
      var taskExpandedAnswers = state.task.answers || []
      const answer = !taskExpandedAnswers.length ? { ...action.answer, correct: true} : action.answer
      return {
        ...state,
        task: { ...state.task, answers: taskExpandedAnswers.concat([answer]) },
      }

    case CHALLENGE_TASK_ANSWER_REMOVE:
      var taskReducedAnswers = state.task.answers || []
      var reducedAnswers = taskReducedAnswers.filter((answer, key) => key !== action.index)
      if(taskReducedAnswers[action.index].correct) {
        reducedAnswers = reducedAnswers.map((answer, key) => key === 0 ? {name: answer.name, correct: true}: answer)
      }
      return {
        ...state,
        task: { ...state.task, answers: reducedAnswers },
      }

    case CHALLENGE_TASK_ANSWER_CORRECT_SET_INDEX:
      var stateTaskAnswers = state.task.answers || []
      return {
        ...state,
        task: { 
          ...state.task, 
          answers: stateTaskAnswers.map((answer, key) => {
            return key === action.index
            ? {...answer, correct: true}
            : {name: answer.name}
          })
        },
      }

    default:
      return state
  }
}