import React from "react"

/* COMPONENTS */
import TaskItem from "./task-item"
import LoadingSpinner from "components/loading-spinner"

export default ({tasks, handleTaskToggle, handleTaskInputChange, handleTaskVideoPlayingToggle}) => (
  <div key="task-list" className="program-task-list">

  	{(!tasks.loaded && tasks.requesting) && (
  		<LoadingSpinner />
  	)}

    {!!tasks.tasks && tasks.tasks.map(task => (
    	<TaskItem
    		key={task.id}
    		item={task}
    		handleTaskToggle={handleTaskToggle}
    		handleTaskInputChange={handleTaskInputChange}
    		handleTaskVideoPlayingToggle={handleTaskVideoPlayingToggle}/>
    ))}
  </div>
)