/* REACT */ 
import React from "react"

import {
  Switch,
  Route
} from "react-router-dom"

import ChallengesAccept from "components/challenges/accept"

export default ({authenticated}) => (
	<div className={authenticated ? "home" : ""}>
		<Switch>
      <Route exact path="/challenges/:challengeId/accept" component={ChallengesAccept}/>
	  </Switch>
	</div>
)