import {
  CHALLENGE_CREATE_REQUEST,
  CHALLENGE_CREATE_CLEAR,
  CHALLENGE_EDIT,
  CHALLENGE_EDIT_CLEAR,
  CHALLENGE_GET_REQUEST,
  CHALLENGE_UPDATE_REQUEST,
  CHALLENGE_DELETE_REQUEST,
  CHALLENGE_ACTIVATE_TOGGLE_REQUEST,
  CHALLENGES_GET_REQUEST,
  CHALLENGE_ACCEPTED_CLEAR,
  CHALLENGE_ACCEPT_REQUEST,
} from "./constants"

export function challengeCreateAction({ name, duration, token }) {
  return {
    type: CHALLENGE_CREATE_REQUEST,
    name,
    duration,
    token,
  }
}

export function challengeCreateClear() {
  return {
    type: CHALLENGE_CREATE_CLEAR,
  }
}

export function challengeGetAction({ challengeId, token }) {
  return {
    type: CHALLENGE_GET_REQUEST,
    challengeId,
    token,
  }
}

export function challengeEditAction({ challenge, token }) {
  return {
    type: CHALLENGE_EDIT,
    challenge,
    token,
  }
}

export function challengeEditedClearAction() {
  return {
    type: CHALLENGE_EDIT_CLEAR,
  }
}

export function challengeUpdateAction({ challenge, token }) {
  return {
    type: CHALLENGE_UPDATE_REQUEST,
    challenge,
    token,
  }
}

export function challengeDeleteAction({ challengeId, token }) {
  return {
    type: CHALLENGE_DELETE_REQUEST,
    challengeId,
    token,
  }
}

export function challengeActivateToggleAction({ challenge, token }) {
  return {
    type: CHALLENGE_ACTIVATE_TOGGLE_REQUEST,
    challenge,
    token,
  }
}

export function challengesGetAction({ token }) {
  return {
    type: CHALLENGES_GET_REQUEST,
    token,
  }
}

export function challengeAcceptedClearAction() {
  return {
    type: CHALLENGE_ACCEPTED_CLEAR,
  }
}

export function challengeAcceptAction({ token, challengeId, referral, callback }) {
  return {
    type: CHALLENGE_ACCEPT_REQUEST,
    token,
    challengeId,
    referral,
    callback,
  }
}