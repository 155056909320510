import {
  CHALLENGE_STATS_USERS_REQUEST,
  CHALLENGE_STATS_USERS_CLEAR,
  CHALLENGE_STATS_USERS_FILTER_SET,
} from "./constants"

export function challengeStatsUsersRequestAction({ token, challengeId, callback }) {
  return {
    type: CHALLENGE_STATS_USERS_REQUEST,
    token,
    challengeId,
    callback,
  }
}

export function challengeStatsUsersClearAction() {
  return {
    type: CHALLENGE_STATS_USERS_CLEAR,
  }
}

export function challengeStatsUsersFilterSet({q}) {
	return {
		type: CHALLENGE_STATS_USERS_FILTER_SET,
		q,
	}
}