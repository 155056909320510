import React from "react"
import {
	Icon,
} from "react-materialize"
import Clipboard from "react-clipboard.js";

export default ({name, id, label, onChange, value, placeholder, type, disabled, copy}) => (
  <div className="app-form-input form-group">
    {label && <label>{label}</label>}
    <input
    	name={name || null}
      id={id || null}
    	className="form-control"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type || "text"}
      disabled={disabled || false}
      />
    {copy && document.queryCommandSupported("copy") && 
      <Clipboard className="btn blue lighten-1 btn-clip-copy waves-effect" data-clipboard-text={value}><Icon>file_copy</Icon></Clipboard>}
  </div>
)