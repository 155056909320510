import {
  REFERRAL_REFRESH_SUCCESS,
} from "./constants"

const initialSate = null

const reducer = function clientReducer(state = initialSate, action) {

  switch (action.type) {
    case REFERRAL_REFRESH_SUCCESS:
      return {
      	referral: action.referral,
      }

    default:
      return state
  }
}

export default reducer
