export const CHALLENGE_CREATE_REQUEST = "CHALLENGE_CREATE_REQUEST"
export const CHALLENGE_CREATE_ERROR = "CHALLENGE_CREATE_ERROR"
export const CHALLENGE_CREATE_SUCCESS = "CHALLENGE_CREATE_SUCCESS"
export const CHALLENGE_CREATE_CLEAR = "CHALLENGE_CREATE_CLEAR"

export const CHALLENGE_GET_REQUEST = "CHALLENGE_GET_REQUEST"
export const CHALLENGE_GET_SUCCESS = "CHALLENGE_GET_SUCCESS"
export const CHALLENGE_GET_ERROR = "CHALLENGE_GET_ERROR"

export const CHALLENGE_EDIT = "CHALLENGE_EDIT"
export const CHALLENGE_EDIT_CLEAR = "CHALLENGE_EDIT_CLEAR"

export const CHALLENGE_UPDATE_REQUEST = "CHALLENGE_UPDATE_REQUEST"
export const CHALLENGE_UPDATE_SUCCESS = "CHALLENGE_UPDATE_SUCCESS"
export const CHALLENGE_UPDATE_ERROR = "CHALLENGE_UPDATE_ERROR"
export const CHALLENGE_UPDATE_CLEAR = "CHALLENGE_UPDATE_CLEAR"

export const CHALLENGE_DELETE_REQUEST = "CHALLENGE_DELETE_REQUEST"
export const CHALLENGE_DELETE_SUCCESS = "CHALLENGE_DELETE_SUCCESS"
export const CHALLENGE_DELETE_ERROR = "CHALLENGE_DELETE_ERROR"

export const CHALLENGE_ACTIVATE_TOGGLE_REQUEST = "CHALLENGE_ACTIVATE_TOGGLE_REQUEST"
export const CHALLENGE_ACTIVATE_TOGGLE_SUCCESS = "CHALLENGE_ACTIVATE_TOGGLE_SUCCESS"
export const CHALLENGE_ACTIVATE_TOGGLE_ERROR = "CHALLENGE_ACTIVATE_TOGGLE_ERROR"

export const CHALLENGE_TASKS_GET_REQUEST = "CHALLENGE_TASKS_GET_REQUEST"
export const CHALLENGE_TASKS_GET_SUCCESS = "CHALLENGE_TASKS_GET_SUCCESS"
export const CHALLENGE_TASKS_GET_ERROR = "CHALLENGE_TASKS_GET_ERROR"

export const CHALLENGE_TASKS_CREATE_REQUEST = "CHALLENGE_TASKS_CREATE_REQUEST"
export const CHALLENGE_TASKS_CREATE_SUCCESS = "CHALLENGE_TASKS_CREATE_SUCCESS"
export const CHALLENGE_TASKS_CREATE_ERROR = "CHALLENGE_TASKS_CREATE_ERROR"

export const CHALLENGE_TASKS_UPDATE_REQUEST = "CHALLENGE_TASKS_UPDATE_REQUEST"
export const CHALLENGE_TASKS_UPDATE_SUCCESS = "CHALLENGE_TASKS_UPDATE_SUCCESS"
export const CHALLENGE_TASKS_UPDATE_ERROR = "CHALLENGE_TASKS_UPDATE_ERROR"

export const CHALLENGE_TASKS_DELETE_REQUEST = "CHALLENGE_TASKS_DELETE_REQUEST"
export const CHALLENGE_TASKS_DELETE_ERROR = "CHALLENGE_TASKS_DELETE_ERROR"

export const CHALLENGES_GET_REQUEST = "CHALLENGES_GET_REQUEST"
export const CHALLENGES_GET_SUCCESS = "CHALLENGES_GET_SUCCESS"
export const CHALLENGES_GET_ERROR = "CHALLENGES_GET_ERROR"

export const CHALLENGE_ACCEPTED_CLEAR = "CHALLENGE_ACCEPTED_CLEAR"
export const CHALLENGE_ACCEPT_REQUEST = "CHALLENGE_ACCEPT_REQUEST"
export const CHALLENGE_ACCEPT_SUCCESS = "CHALLENGE_ACCEPT_SUCCESS"
export const CHALLENGE_ACCEPT_ERROR = "CHALLENGE_ACCEPT_ERROR"

export const CHALLENGE_TASK_EDIT = "CHALLENGE_TASK_EDIT"
export const CHALLENGE_TASK_EDIT_CLEAR = "CHALLENGE_TASK_EDIT_CLEAR"
export const CHALLENGE_DAY_TASK_CREATE_INDEX = "CHALLENGE_DAY_TASK_CREATE_INDEX"
export const CHALLENGE_DAY_TASK_CREATE_INDEX_CLEAR = "CHALLENGE_DAY_TASK_CREATE_INDEX_CLEAR"
