import React from "react"
import {
	t,
	gettext,
} from "ttag"
import {
  Row,
  Col,
  Card,
  TextInput,
  Checkbox,
  Select,
} from "react-materialize"

import FormErrors from "components/form-errors"
import FormActionButton from "components/form-action-button"
import FormBackLink from "components/form-backlink"
import {
	LanguageSwitcher,
} from "components/locale"

import {
  Redirect,
} from "react-router-dom"

import {
  getItemByObjKey,
} from "services/functions"

import {
  FIELDS,
} from "./constants"

const INITIAL_STATE = {
  data: {
  	displayName: "",
	  email: "",
	  password: "",
	  passwordConfirm: "",
	},
	errors: [],
}

class Register extends React.Component {

	state = {
		...INITIAL_STATE,
	}

  componentWillUnmount() {
    this.props.registerClearAction()
  }

  getValidationErrors = () => {

  	const {data} = this.state

    var errors = []
    for(var i=0; i < FIELDS.length; i += 1) {
      var field = FIELDS[i]
      if(!field.required) {
        continue;
      }

      const {name} = field
      if(data[name].trim() === "") {
        var errObj = {
          key: gettext(field.error),
          name: name,
        }
        errors.push(errObj)
      }
    }

    if(errors.length) {
    	return errors
    }

    if(data.email === "" || data.password === "" || data.passwordConfirm === "")
      return [{key: t`Please populate all fields.`, name: "phoneNumber"}]

    if(data.password !== data.passwordConfirm)
      return [{key: t`Passwords do not match.`, name: "password"}]



    return [];
  }
  
  signUp = () => {

    const {
    	registerRequestAction,
    	requesting,
    	cb,
    } = this.props
    
    const errors = this.getValidationErrors()
    this.setState({errors})
    if(errors.length) {
      return
    }

    if(requesting)
      return

    const {displayName, email, password} = this.state.data

    registerRequestAction({
    	displayName: displayName.trim(),
      email: email.trim(),
      password: password.trim(),
      language: "en",
      cb: cb,
    })
    
  }

  onChangeHandler = (target, fieldName) => {

    var fieldType = target.type
    var fieldValue = target.value
    var fieldObj = {}
    const {data} = this.state
    
    switch(fieldType) {
      case "number":
        var fieldNumberValue = parseInt(fieldValue)
        if(fieldValue !== "" && 
          (isNaN(fieldNumberValue) || fieldNumberValue < 1)
          ) {
          return;
        }

        fieldObj[fieldName] = fieldNumberValue;
      break;

      case "checkbox":
      	fieldObj[fieldName] = !data[fieldName]
      break;

      default:
    	 	fieldObj[fieldName] = fieldValue;
      break;
    }

    this.setState({
      data: {
      	...data,
      	...fieldObj,
      },
    })
  }

  renderInputField = (field) => {
    const {errors} = this.state
    const {type, name, label, grid, options, icon, required, disabled, placeholder, displayIfStateAttrTrue} = field
    const errored = getItemByObjKey(errors, "name", name)

    if(displayIfStateAttrTrue && !this.state[displayIfStateAttrTrue]) {
      return false;
    }

    switch(type) {
      case "text":
      case "number":
      case "email":
      case "password":
        return (
          <TextInput
            key={name}
            className={errored ? "errored" : ""}
            s={grid.s}
            m={grid.m}
            xl={grid.xl}
            name={name}
            icon={icon || null}
            label={gettext(label) + (required ? " *" : "")}
            onChange={!disabled ? (e => this.onChangeHandler(e.target, name)) : () => {}}
            value={this.state.data[name]}
            type={type}
            min={type === "number" ? 1 : null}
          	required={required}
          	disabled={disabled || false}
          	placeholder={placeholder}/>
        )

      case "select":
        return (
          <Select
            key={name}
            className={errored ? "errored" : ""}
            label={gettext(label) + (required ? " *" : "")}
            s={grid.s}
            m={grid.m}
            xl={grid.xl}
            name={name} onChange={e => this.onChangeHandler(e.target, name)}
            value={this.state.data[name]}
            disabled={disabled || false}>
            {options.map((race, index) => <option key={`race-${index}`} value={race}>{race}</option>)}
          </Select>
        )

      case "checkbox":
        return (
        	<Col
        		key={name}
        		className="input-group mt1"
        		s={grid.s}
        		m={grid.m}
        		xl={grid.xl}
        		>
            <Checkbox
              value={name}
              onChange={name === "deliveryAddressDifferent" ? e => this.setState({deliveryAddressDifferent: !this.state.deliveryAddressDifferent}) : null}
              label={gettext(label)}/>
          </Col>
        )

      default:
        return false
    }
  }

  render() {

    const { requesting }  = this.props.register
    const { errors } = this.state
    const { authenticated, onClickToLogin, submitButtonTextOverride } = this.props
    const {
    	header,
    } = this.props

    if(authenticated) {
      return <Redirect to={{ pathname: "/" }} />
    }

    return (
    	<React.Fragment>
	      <Card
	        className="border-radius-6 z-depth-4 animate fadeUp"
	        title={!header ? t`Registration` : ""}
	        actions={[
	          <FormErrors key="form-errors" errors={errors.concat(this.props.register.errors)}/>,
	          <Row key="form-action">
	            <Col s={12} className="input-field">
	              <FormActionButton
	                className="blue lighten-1 border-round col s12"
	                waves="light"
	                onClick={this.signUp}
	                text={(submitButtonTextOverride || t`Register`).toUpperCase()}
	                inProgress={requesting}/>
	            </Col>
	          </Row>,
	          <Row key="to-login-action">
	            <Col className="input-field" s={12}>
	              <FormBackLink onClick={onClickToLogin} link="/login"  text={t`Back to login`} />
	            </Col>
	          </Row>
	        ]}>

	        {!!header && header}

	      	<Row className="input-group">
	          <Row>
	            <Col s={12} className="row divider"></Col>
	          </Row>
	        </Row>

	        <Row>
	          {FIELDS.map((field, index) => this.renderInputField(field))}
	        </Row>
	          
	      </Card>
	    	<LanguageSwitcher compact/>
	    </React.Fragment>
    )
  }
}

export default Register