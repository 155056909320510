/* REDUX */
import { connect } from "react-redux"

import {
	challengeDayTaskCreateAction,
	challengeDayTaskCreateClearAction,
	challengeTasksCreateAction,
} from "components/challenges/tasks/actions"

/* THE COMPONENT */
import {ChallengeDayTasksCreate} from "./component" 

const mapStateToProps = state => ({
  challengeEdited: state.challengeEdited,
  challengeTaskEdited: state.challengeTaskEdited,
})

const actions = {
	challengeDayTaskCreateAction,
	challengeDayTaskCreateClearAction,
	challengeTasksCreateAction,
}

export default connect(mapStateToProps, actions)(ChallengeDayTasksCreate)