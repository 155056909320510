/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	loginRequestAction,
	loginClearAction,
} from "./actions"

/* THE COMPONENT */
import Login from "./component" 

const mapStateToProps = state => ({
  login: state.login,
})

const actions = {
	loginRequestAction,
	loginClearAction,
}

export default connect(mapStateToProps, actions)(Login)