import React from "react"
import { Switch, Route } from "react-router-dom"
import {
	ROUTES,
} from "components/routes"

import NoMatch from "components/no-match"

export default () => (
	<Switch>
		{ROUTES.length && ROUTES.map((route, key) => (
			<Route
				key={`route-component-${route-key}`}
				exact={route.exact || false}
				path={route.pathname}
				component={route.component}
				/>
		))}
    <Route component={NoMatch}/>
  </Switch>
)