import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_VALIDATION_ERROR,
  FORGOT_PASSWORD_CLEAR
} from "./constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
}


export default function(state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        requesting: true,
        success: false,
        errors: [].concat(state.errors),
      }

    case FORGOT_PASSWORD_SUCCESS:
      return {
        errors: [],
        requesting: false,
        success: true,
      }

    case FORGOT_PASSWORD_ERROR:
      return {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      }

    case FORGOT_PASSWORD_VALIDATION_ERROR:
      return {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      }

    case FORGOT_PASSWORD_CLEAR:
      return {
        requesting: false,
        success: false,
        errors: [],
      }

    default:
      return state
  }
}
