import {
  CHALLENGE_GET_SUCCESS,
  CHALLENGE_GET_ERROR,
  CHALLENGE_ACCEPT_SUCCESS,
  CHALLENGE_ACCEPTED_CLEAR,
} from "../constants"

const initialState = {
  loaded: false,
  requesting: false,
  success: false,
  messages: [],
  errors: [],
  challenge: null,
  tasks: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHALLENGE_GET_SUCCESS:
      return {
      	...state,
      	loaded: true,
      	challenge: {
      		...action.challenge,
      	},
      }

    case CHALLENGE_GET_ERROR:
      return {
      	...state,
      	requesting: false,
      	loaded: true,
      	errors: [
      		...action.errors,
      	],
      }

    case CHALLENGE_ACCEPT_SUCCESS:
      return {
      	...state,
      	loaded: true,
      	success: true,
      	messages: [].concat(action.messages || []),
      }

    case CHALLENGE_ACCEPTED_CLEAR:
      return {
      	...initialState,
      }

    default:
      return state
  }
}