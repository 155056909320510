import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Days from "./component"
import {
	challengeStatsDaysRequestAction,
	challengeStatsDaysClearAction,
} from "./actions"

const mapStateToProps = state => ({
	token: state.client.token,
  challengeStatsDays: state.challengeStatsDays,
})

const actions = {
	challengeStatsDaysRequestAction,
	challengeStatsDaysClearAction,
}

export default withRouter(connect(mapStateToProps, actions)(Days))