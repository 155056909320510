import { call, put, takeLatest } from "redux-saga/effects"

import {
  CHALLENGE_STATS_DAYS_REQUEST,
  CHALLENGE_STATS_DAYS_ERROR,
  CHALLENGE_STATS_DAYS_SUCCESS,
} from "./constants"

import {
  challengeStatsDaysGetApi,
} from "services/api-helpers"

export function* challengeStatsDaysRefreshFlow({token, challengeId, callback}) {
  let response
  try {
    response = yield call(challengeStatsDaysGetApi, {token, challengeId})
    if("undefined" !== typeof response.httpStatus) {
     yield put({ type: CHALLENGE_STATS_DAYS_ERROR, errors: [{key: "Network error."}] })
    }

    yield put({ type: CHALLENGE_STATS_DAYS_SUCCESS, days: response })
    if(typeof callback !== "undefined") {
      yield call(callback)
    }
    
  } catch (error) {
    yield put({ type: CHALLENGE_STATS_DAYS_ERROR, errors: [{key: "Network error."}] })

  }

  return response
}

export default function* ChallengeStatsDaysWatcher() {
  yield takeLatest(CHALLENGE_STATS_DAYS_REQUEST, challengeStatsDaysRefreshFlow)
}
