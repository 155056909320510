/* REDUX */
import { connect } from "react-redux"

import {
	challengeGetAction,
	challengeAcceptedClearAction,
	challengeAcceptAction,
} from "../actions"

/* THE COMPONENT */
import ChallengesAccept from "./component" 

const mapStateToProps = state => ({
	token: state.client.token,
  challengeAccepted: state.challengeAccepted,
  program: state.program,
  referral: state.referral,
})

const actions = {
	challengeGetAction,
	challengeAcceptedClearAction,
	challengeAcceptAction,
}

export default connect(mapStateToProps, actions)(ChallengesAccept)