import React from "react"
import { t, gettext } from "ttag"
import { Redirect } from "react-router-dom"
import {
  Row,
  Col,
  Card,
	TextInput,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"

import {
  route,
} from "services/functions"
import {
	getLocale,
} from "components/locale/init"

/* COMPONENTS */ 
import LoadingSpinner from "components/loading-spinner"
import FormInput from "components/form-input"
import FormErrors from "components/form-errors"
import FormActionButton from "components/form-action-button"
import { ChallengeActivator } from "components/challenges/components"
import ChallengeEditTasks from "components/challenges/tasks"

class ChallengeEdit extends React.PureComponent {

  static defaultProps = {
    labels: {
      ChallengeName: `Challenge name`,
      TypeInChallengeName: `Type in challenge name...`,
      TypeInNumberOfDays: `Type in number of days...`,
      ChallengeDurationInDays: `Challenge duration`,
      AcceptUrl: `Accept url`,
      MainFormUpdateText: `Save`,
      AreYouSureToActivateThisChallenge: `Do you really want to activate this challenge?`,
      AreYouSureToDeactivateThisChallenge: `Do you really want to deactivate this challenge?`,
      ChallengeIsInactive: `Challenge is inactive`,
      ChallengeIsActive: `Challenge is active`,
    }
  }

  componentDidMount() {
    const {challengeId} = this.props.match.params
    const {token} = this.props
    this.props.challengeGetAction({challengeId, token})
  }

  componentWillUnmount() {
    this.props.challengeEditedClearAction()
  }

  handleToggleActivateChallenge = (challengeId) => {

    const {token} = this.props
    const {challenge} = this.props.challengeEdited

    this.props.challengeActivateToggleAction({challenge, token})
  }

  handleValueChange = (fieldName, fieldValue) => {

    const {token} = this.props
    const {challenge} = this.props.challengeEdited

    switch(fieldName) {
      case "duration":
        if (fieldValue === "") {
          this.props.challengeEditAction({
            challenge: Object.assign( {}, challenge, {duration: fieldValue} ),
            token,
          })
        } else if(!isNaN(parseInt(fieldValue, 10))) {
          this.props.challengeEditAction({
            challenge: Object.assign( {}, challenge, {duration: parseInt(fieldValue, 10)} ),
            token,
          })
        }
        
      break;

      case "name":
        this.props.challengeEditAction({
          challenge: Object.assign( {}, challenge, {name: fieldValue} ),
          token,
        })
      break;

      default:
      break;
    }
    
  }

  handleSubmitForm = () => {

    const {challenge} = this.props.challengeEdited
    const {token} = this.props
    this.props.challengeUpdateAction({
      challenge: Object.assign({}, challenge),
      token
    })
   
  }

  render() {

    const {unauthorized, requesting, errors, challenge} = this.props.challengeEdited
    if(!requesting && unauthorized) {
      return <Redirect to="/challenges" />
    }


    return (
      <Row>

		  	{challenge && (
		  		<Breadcrumbs links={[
			  		{pathname: "/challenges", text: t`My challenges`},
			  		{pathname: `/challenges/${challenge.id}/dashboard`, text: challenge.name},
			  		{pathname: `/challenges/${challenge.id}/edit`, text: t`Edit`},
			  	]}/>
			  )}

				<Row>
					<Col s={12} m={12} l={12}>
						<Card
					  	className="animate fadeLeft">
        
		        {!challenge && <div className="app-card-head"><LoadingSpinner /></div>}

		        {challenge && (
		          <React.Fragment>
		            <ChallengeActivator challenge={challenge} handleToggleActivateChallenge={this.handleToggleActivateChallenge} />

		            <Row>
			            <TextInput
			            	s={12}
			            	m={12}
			            	l={12}
			            	label={gettext(this.props.labels.ChallengeName)}
			              onChange={e => challenge.active || this.handleValueChange("name", e.target.value)}
			              value={challenge.name}
			              placeholder={gettext(this.props.labels.TypeInChallengeName)}/>
		            </Row>

		            <Row>
			            <TextInput
			            	s={12}
			            	m={12}
			            	l={12}
			            	label={gettext(this.props.labels.ChallengeDurationInDays)}
			              onChange={e => challenge.active || this.handleValueChange("duration", e.target.value)}
			              value={challenge.duration.toString()}
			              placeholder={gettext(this.props.labels.TypeInNumberOfDays)}/>
			          </Row>

		            <FormErrors errors={errors}/>

		            {!challenge.active && (
	                <FormActionButton
	                	className="blue lighten-1 waves-effect"
	                  onClick={this.handleSubmitForm}
	                  text={gettext(this.props.labels.MainFormUpdateText)}
	                  inProgress={requesting}
	                  size="lg"/>
		            )}

		            {challenge.active && (
		              <div className="app-form-group">
		                <FormInput
		                  id="acceptUrl"
		                  label={this.props.labels.AcceptUrl}
		                  value={route('challenges.accept', [challenge.id]) + '?loc=' + getLocale()}
		                  placeholder={gettext(this.props.labels.TypeInNumberOfDays)}
		                  disabled={true}
		                  copy={true}
		                />
		              </div>
		            )} 

		            <ChallengeEditTasks />

		          </React.Fragment>
		        )}
      			</Card>
					</Col>
				</Row>

		  </Row>
    )
  }

}

export default ChallengeEdit;