import React from "react"
import { gettext } from "ttag"
import {
	Button,
	Row,
	Col,
	TimePicker,
} from "react-materialize"
import MdClose from "react-ionicons/lib/MdClose"

const labels = {
  Save: `Save`,
  NotificationTime: `Notification time`,
}

export default ({
	task,
	onChangeHandler,
	onSubmitHandler,
	clearFormActionHandler,
	taskCategories,
	taskTypeOptions,
	taskType,
	clearTaskNameEdited,
}) => (
  <div className="challenges_form-days-task_create clear row">
    <Row
    	className="input-field">
    	<TimePicker
    		placeholder={gettext(labels.NotificationTime)}
	      data-key="completeTime"
	      options={{
	    		twelveHour: false,
	      }}
	      onSelect={onChangeHandler}/>
    </Row>
    <Row>
      <Col 
      	s={12}
      	m={12}
      	l={12}
      	className="task-actions">
        <Button className="blue lighten-1 mr5" onClick={onSubmitHandler}>{gettext(labels.Save)}</Button>
        <Button className="blue lighten-1" onClick={clearFormActionHandler}><MdClose color="#fff" fontSize="16px"/></Button>
      </Col>
    </Row>
  </div>
)