import {
  CLIENT_SET,
  CLIENT_UNSET
} from "./constants"

const initialSate = {
  token: null,
  user: null
}

const reducer = function clientReducer(state = initialSate, action) {

  switch (action.type) {
  	case CLIENT_SET:
      return { ...state,
        token: action.user.authToken.trim(),
        user: action.user.user,
      }

    case CLIENT_UNSET:
      return { ...state,
        token: null,
        user: null,
      }

    default:
      return state
  }
}

export default reducer
