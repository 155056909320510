/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	dayMoveBackAction,
	dayMoveForwardAction,
} from "components/daily-tasks/actions"

/* THE COMPONENT */ 
import DayChangeNavigation from "./component"

const mapStateToProps = state => ({
  tasks: state.tasks,
  program: state.program,
})

const actions = {
	dayMoveBackAction,
	dayMoveForwardAction,
}

export default connect(mapStateToProps, actions)(DayChangeNavigation)