import React from "react"
import {
  Row,
  Col,
  Card,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"
import { t } from "ttag"

import {
	Redirect,
	Link,
} from "react-router-dom"

/* COMPONENTS */ 
import LoadingSpinner from "components/loading-spinner"
import FormActionButton from "components/form-action-button"
import Auth from "components/views/auth/component"

class ChallengeAccept extends React.Component {

  static defaultProps = {
    labels: {
      ChallengeName: t`Challenge name`,
      TypeInChallengeName: t`Type in challenge name...`,
      TypeInNumberOfDays: t`Type in number of days...`,
      ChallengeDurationInDays: t`Challenge duration`,
      MainFormUpdateText: t`Accept challenge`,
      AreYouSureToActivateThisChallenge: t`Do you really want to activate this challenge?`,
      AreYouSureToDeactivateThisChallenge: t`Do you really want to deactivate this challenge?`,
      ChallengeIsInactive: t`Challenge is inactive`,
      ChallengeIsActive: t`Challenge is active`,
    }
  }

  componentDidMount() {
    const {challenge, requesting} = this.props.challengeAccepted
    const {challengeId} = this.props.match.params
    const {token} = this.props
    if(!challenge && !requesting)
      this.props.challengeGetAction({challengeId, token})
    
  }

  componentWillUnmount() {
    this.props.challengeAcceptedClearAction()
  }

  handleChallengeAcceptSubmit = () => {
    const {token, referral} = this.props
    const {challenge} = this.props.challengeAccepted
    this.props.challengeAcceptAction({token, challengeId: challenge.id, referral})
  }

  render() {

    const {requesting, loaded, challenge, success, messages} = this.props.challengeAccepted
    const {token} = this.props

    if(!loaded) {
    	return false
    }

    if(!token) {

      return (
      	<Row className="app-auth">
					<Col offset="m2 l3" m={8} s={12} l={6}>

						{(!challenge || !challenge.active) && (
							<Card className="animate fadeUp center-align">
		            <div className="mb15">{t`404 - challenge not found`}</div>
		            <Link
		            	className="btn blue lighten-1"
		            	to="/login"
		            	>{t`Login`}</Link>
		          </Card>
						)}

						{challenge && (
							<Auth 
	              header={<div className="app-card-head centered">
	                <h1>{challenge.name}</h1>
	                <div>{t`Duration in days`}: {challenge.duration}</div>
	              </div>}
	              cb={this.handleChallengeAcceptSubmit}
	            />
	          )}
          </Col>
        </Row>
      )

    }

    const {program} = this.props.program
    if(token && loaded && program) {
      return <Redirect to="/" />
    }

    const {challengeId} = this.props.match.params
    
    return (
      <Row>
      	{(!challenge || !challenge.active) ? (
      		<Breadcrumbs links={[
		  		{pathname: `/challenges/${challengeId}/accept`, text: t`Accept challenge` + ` ${challengeId}`},
		  	]}/>
      	) : (
			  	<Breadcrumbs links={[
			  		{pathname: `/challenges/${challenge.id}/accept`, text: t`Accept challenge` + ` ${challenge.name}`},
			  	]}/>
      	)}

				<Row>
					<Col s={12} m={12} l={12}>
						<Card
					  	className="animate fadeLeft">
        
			        {!challenge && requesting && (
			        	<div className="app-card-head"><LoadingSpinner /></div>
			        )}

			        {(!challenge || !challenge.active) &&(
		            <div className="app-card-head">{t`404 - challenge not found`}</div>
			        )}

			        {challenge && (
			          <div className="app-card-head centered">

			            <div className="app-form-group">
			              <h1>{challenge.name}</h1>
			              <div>{t`Duration in days`}: {challenge.duration}</div>
			            </div>

			            {challenge.active && !success && (
			            	<div className="app-form-action">
			                <FormActionButton
			                 	className="blue lighten-1 waves-effect"
			                  onClick={this.handleChallengeAcceptSubmit}
			                  text={this.props.labels.MainFormUpdateText}
			                  inProgress={requesting}
			                  size="lg"/>
			              </div>
			            )}

			            {messages.length ? (
			              <div className="app-form-group">
			                {messages.map(msg => <div className="text-success lead" key={msg.key}>{msg.key}</div>)}
			              </div>
			            ) : false}

			          </div>
			        )}
						</Card>
					</Col>
				</Row>

		  </Row>
    );
  }

}

export default ChallengeAccept;