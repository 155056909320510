import React from "react"
import { t } from "ttag"

/* COMPONENTS */ 
import Login from "components/login"
import Register from "components/register"
import ForgotPassword from "components/forgot-password"

export default class Auth extends React.Component {

	state = {
    view: "register",
  }

  static defaultProps = {
    labels: {
      ChallengeName: t`Challenge name`,
      TypeInChallengeName: t`Type in challenge name...`,
      TypeInNumberOfDays: t`Type in number of days...`,
      ChallengeDurationInDays: t`Challenge duration`,
      MainFormUpdateText: t`Accept challenge`,
      AreYouSureToActivateThisChallenge: t`Do you really want to activate this challenge?`,
      AreYouSureToDeactivateThisChallenge: t`Do you really want to deactivate this challenge?`,
      ChallengeIsInactive: t`Challenge is inactive`,
      ChallengeIsActive: t`Challenge is active`,
    }
  }

  render() {

    const { header, cb } = this.props

    switch(this.state.view) {
      case "login":
        return <Login
                  header={header}
                  onClickToSignup={() => this.setState({ view: "register"})}
                  onClickToResetPassword={() => this.setState({ view: "forgot-password"})}
                  cb={cb}
                  submitButtonTextOverride={t`Login and accept challenge`}
                  />

      case "register":
        return <Register
                  header={header}
                  onClickToLogin={() => this.setState({ view: "login"})}
                  cb={cb}
                  submitButtonTextOverride={t`Sign Up and accept challenge`}
                />

      case "forgot-password":
        return <ForgotPassword
                  header={header}
                  onClickToLogin={() => this.setState({ view: "login"})}
                />

      default:
        return false;
    }
  }

}