import React from "react"
import { t } from "ttag"

import {
  Redirect,
} from "react-router-dom"

import {
  Row,
  Col,
  Card,
  TextInput,
} from "react-materialize"

import {
	FORGOT_PASSWORD_URL,
} from "components/routes/constants"

/* COMPONENTS */
import FormErrors from "components/form-errors"
import FormActionButton from "components/form-action-button"
import FormBackLink from "components/form-backlink"
import {
	LanguageSwitcher,
} from "components/locale"

const initialState = {
  email: "",
}

export default class ForgotPassword extends React.Component {
  
  state = {
  	...initialState,
  }

  componentWillUnmount() {
    this.props.forgotPasswordClearAction()
  }

  getValidationErrors = () => {
    if(this.state.email === "")
      return t`Please type in your email in the correct format.`

    return false;
  }
  
  forgotPassword = () => {
    
    const error = this.getValidationErrors()
    if(error) {
      this.props.forgotPasswordValidationErrorAction({errors: [{key: error}]})
      return
    }

    const {
    	forgotPasswordClearAction,
    	forgotPassword,
    } = this.props

    const {
    	success,
    	requesting,
    } = forgotPassword

    if(success || requesting) {
      return
    }

    const {email} = this.state
    const url = document.location.origin + FORGOT_PASSWORD_URL

    this.props.forgotPasswordRequestAction({
    	email,
    	url,
    	callback: () => {
    		forgotPasswordClearAction()
    		this.setState({email: ""})
    	}
    })
    
  }

  render() {

    const { errors, requesting, success}  = this.props.forgotPassword
    const { authenticated, onClickToLogin } = this.props

    if(authenticated) {
      return <Redirect to={{ pathname: "/" }} />
    }

    return (
    	<React.Fragment>
	      <Card
	        className="border-radius-6 z-depth-4"
	        title={t`Forgot password`}
	        actions={[
	          <FormErrors key={"form-errors"} errors={errors}/>,
	          <Row key="forgot-password-submit" >
	            <Col s={12} className="input-field">
	              <FormActionButton
	                className="blue lighten-1 border-round col s12"
	                waves="light"
	                onClick={this.forgotPassword}
	                text={t`Send link for password reset`}
	                inProgress={requesting}
	                success={success}
	                successText={t`Link has been successfully sent`}/>
	            </Col>
	          </Row>,
	          <Row key="back-to-login-link" >
	            <Col className="input-field" s={12}>
	              <FormBackLink onClick={onClickToLogin} link="/login"  text={t`Back to login`} />
	            </Col>
	          </Row>
	        ]}
	      >
	        <Row>
	          <TextInput
	            s={12}
	            icon="mail_outline"
	            label={t`Email`}
	            onChange={e => this.setState({email: e.target.value})}
	            value={success ? "" : this.state.email}/>
	        </Row>
	      </Card>
	    	<LanguageSwitcher compact/>
	    </React.Fragment>
    )
  }
}