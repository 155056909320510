import {
  LOGIN_REFRESH,
  LOGIN_REQUEST,
  LOGIN_CLEAR,
  LOGIN_CANCEL,
  LOGOUT,
  FACEBOOK_LOGIN_REQUEST,
} from "./constants"

export function loginRefresh() {
  return {
    type: LOGIN_REFRESH,
  }
}

export function loginRequestAction({ email, password, cb }) {
  return {
    type: LOGIN_REQUEST,
    email,
    password,
    cb,
  }
}

export function loginClearAction() {
  return {
    type: LOGIN_CLEAR,
  }
}

export function loginCancelAction() {
  return {
    type: LOGIN_CANCEL,
  }
}

export function logoutAction() {
	return {
    type: LOGOUT,
  }
}

export function facebookLoginAction() {
  return {
    type: FACEBOOK_LOGIN_REQUEST,
  }
}