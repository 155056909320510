import React from "react"
import { t, gettext } from "ttag"
import {
  Row,
  Col,
  Card,
  Icon,
  Button,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"
import { Link } from "react-router-dom"
import LoadingSpinner from "components/loading-spinner"


class ChallengesList extends React.Component {

  static defaultProps = {
    labels: {
      "My Challenges": t`My Challenges`,
      "Name": t`Name`,
      "Are you sure to delete this challenge?": t`Are you sure to delete this challenge?`,
      "You have no challenges.": t`You have no challenges.`,
      "Create challenge": t`Create challenge`,
      "Delete challenge": t`Delete challenge`,
    }
  }

  state = {
    name: "",
    duration: "",
  }

  componentDidMount() {
    const {token} = this.props
    this.props.challengesGetAction({token})
  }

  handleDeleteChallenge = (challengeId) => {
    const {confirm} = window
    const {token, labels} = this.props

    if(!confirm(gettext(labels[`Are you sure to delete this challenge?`]))) {
      return false
    }

    this.props.challengeDeleteAction({challengeId, token})
  }

  renderListItem = (challenge, key) => {
  	const {props} = this
    return (
      <tr key={`challenge-${key}`}>
        <td>
        	<Link title={t`Dashboard`} to={`/challenges/${challenge.id}/dashboard`}>{challenge.name}</Link>
        </td>
        <td>
        	<Link title={t`Edit`} to={`/challenges/${challenge.id}/edit`}>
        		<Icon className="blue-text text-lighten-1">edit</Icon>
        	</Link>
        	<Link title={t`Dashboard`} className="ml8" to={`/challenges/${challenge.id}/dashboard`}>
        		<Icon className="blue-text text-lighten-1">dashboard</Icon>
        	</Link>
        </td>
        <td className="center-align">
        	{challenge.active ? (
        		<Icon className="green-text text-darken-2" center>check_circle</Icon>
        	) : (
	        	<Icon>radio_button_unchecked</Icon>
        	)}</td>
        <td className="center-align">{!challenge.active && (
        	<span
        		title={gettext(props['Delete challenge'])}
        		className="red-text text-darken-2 point"
        		role="button"
        		onClick={() => this.handleDeleteChallenge(challenge.id)}>
	        	<Icon>delete_forever</Icon>
	        </span>
        	)}
       	</td>
      </tr>
    )
  }

  render() {

    const {requesting, challenges, loaded} = this.props.challengesList
    const {history, labels} = this.props 

    return (
      <Row>

		  	<Breadcrumbs links={[
		  		{pathname: "/challenges", text: t`My challenges`},
		  	]}/>

				<Row>
					<Col s={12} m={12} l={12}>
						<Card
					  	className="animate fadeLeft">

		          {!loaded && requesting && (
		          	<div className="app-card-head"><LoadingSpinner /></div>
		          )}

		          {!!challenges.length && (
		          	<Row>
				          <table className="subscription-table highlight striped">
					          <thead>
					            <tr>
					              <th>{t`Name`}</th>
					              <th>{t`Actions`}</th>
					              <th className="center-align">{t`Active`}</th>
					              <th></th>
					            </tr>
					          </thead>
					          <tbody>
					          	{challenges.map(this.renderListItem)}
					          </tbody>
					        </table>
					      </Row>
		          )}

		          {loaded && !challenges.length && (
		            <Row>
		              <div>{labels["You have no challenges."]}</div>
		              <div className="input-field">
			              <Button
			              	className="btn blue lighten-1 waves-effect"
			              	onClick={e => {
			              		e.preventDefault()
			              		history.push("/challenges/create")
			              	}}>
			              	<Icon left>add</Icon> {labels["Create challenge"]}
			              </Button>
			            </div>
		            </Row>
		          )}
		          
		        </Card>
					</Col>
				</Row>

		  </Row>
    )
  }

}

export default ChallengesList;