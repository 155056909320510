import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import AppBody from "./component"

const mapStateToProps = state => ({
  token: state.client.token,
  challengeEdited: state.challengeEdited,
  challengeDisplayed: state.challengeDisplayed,
})

const connected = connect(mapStateToProps, null)(AppBody)

export default withRouter(connected)