import React from "react"
import { t } from "ttag"
import {
	Row,
	Col,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"


import {
	Days,
	Users,
	Scoreboard
} from "./components"

class Dashboard extends React.PureComponent {

	componentDidMount() {
    const {challengeId} = this.props.match.params
    const {token} = this.props
    this.props.challengeGetAction({challengeId, token})
	}

  componentWillUnmount() {
    // this.props.challengeEditedClearAction()
  }

  _challengeLoaded = () => {
  	const {challenge} = this.props.challengeEdited

   	if(!challenge) {
   		return false
   	}

   	return challenge
  }

	render() {
		const {token} = this.props
		if(!token) {
			return false
		}

		const challenge = this._challengeLoaded()
		if(!challenge) {
			return false
		}

		const {scoreboard} = challenge;

		return (
			<Row>

				<Breadcrumbs links={[
					{pathname: "/challenges", text: t`My challenges`},
					{pathname: `/challenges/${challenge.id}/dashboard`, text: challenge.name},
				]} />

				<Row>
					<Col s={12} m={12}>
						<Days />
					</Col>
				</Row>

				<Row>
					<Col s={12} m={12}>
						<Users />
					</Col>
				</Row>

				<Row>
					<Col s={12} m={12}>
						<Scoreboard scoreboard={scoreboard}/>
					</Col>
				</Row>

			</Row>
		)
	}
}

export default Dashboard