import {
  CHALLENGE_STATS_USERS_REQUEST,
  CHALLENGE_STATS_USERS_SUCCESS,
  CHALLENGE_STATS_USERS_ERROR,
  CHALLENGE_STATS_USERS_CLEAR,
  CHALLENGE_STATS_USERS_FILTER_SET,
} from "./constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
  loaded: false,
  users: [],
  q: "",
}

export default function(state = initialState, action) {
  switch (action.type) {

    case CHALLENGE_STATS_USERS_REQUEST:
      return {
        ...state,
        requesting: true,
      }

    case CHALLENGE_STATS_USERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        loaded: true,
        success: true,
        users: [
        	...action.users,
        ],
      }

    case CHALLENGE_STATS_USERS_ERROR:
      return {
        ...state,
        errors: [
        	...action.errors,
        ],
        success: false,
      }

    case CHALLENGE_STATS_USERS_CLEAR:
      return {
        ...initialState,
      }

    

    case CHALLENGE_STATS_USERS_FILTER_SET:
    	return {
    		...state,
    		q: action.q,
    	}

    default:
      return state
  }
}