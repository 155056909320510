import React from "react"
import { t, gettext } from "ttag"
import {
  SideNav,
  SideNavItem,
  Icon
} from "react-materialize"
import {
	Link,
} from "react-router-dom"

import {
	ROUTES,
} from "components/routes"

import {
	LanguageSwitcher,
} from "components/locale"

import LogoutLink from "components/logout-link"
import {
	BrandPng,
} from "components/brand"

class SideMenu extends React.Component {

  componentDidMount() {
  	const {program, token} = this.props
  	if(token && !program.loaded) {
  		this.props.currentProgramAction({token})
  	}
  }

  _handleCloseMenu = () => {
  	this.refs["side-menu"].instance.close()
  }

  _handleLinkNavigate = (e, pathName) => {
  	e.preventDefault()
  	const {history} = this.props
		history.push(pathName)
		if(window.innerWidth <= 992) {
			this._handleCloseMenu()
		}
  }

  _renderCurrentProgramItem = () => {

  	const {tasks, program} = this.props
  	const {pathname} = this.props.location
  	if(program && tasks && tasks.tasks) {
	  	return [
		  	<li key="side-menu-item-daily-tasks" className={"/" === pathname ? "active" : "'"}>
		  		<Link
		  			to="/"
		  			onClick={e => {
		  				this._handleLinkNavigate(e, "/")
		  			}}>
		  			<Icon>today</Icon>
		    		{t`My daily tasks` + ` - ${tasks.tasksCompletedCount}/${tasks.tasks.length}`}
		    	</Link>
		    </li>,
		    <SideNavItem key="side-menu-item-daily-tasks-separator" divider />
	  	]

  	}

  	return false
  }

  render() {

    const {token, user} = this.props
    if(!token)
      return false;

    const {pathname} = this.props.location

    return (
  		<SideNav className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow ps" ref="side-menu" trigger={<span></span>} id="side-menu" options={{closeOnClick: true}}>
        <li>
          <div className="user-view">
            <div className="background white"></div>
            <span className="brand-logo"><BrandPng /></span>
            {user.email && <span className="black-text email">{user.email}</span>}
            <LanguageSwitcher />
          </div>
        </li>

        {this._renderCurrentProgramItem()}
        {ROUTES
        	.filter(item => item.menu)
        	.map((item, key) => {
		        return (
		        	<li key={`side-menu-${key}`} className={item.pathname === pathname ? "active" : ""}>
		        		<Link
		        			to={item.pathname}
		        			onClick={e => {
		        				this._handleLinkNavigate(e, item.pathname)
		        			}}>
		        			{!!item.menuIcon && (
			        			<Icon>{item.menuIcon}</Icon>
			        		)}
			        		{gettext(item.label)}
			        	</Link>
		        	</li>
						)
        })}

        <SideNavItem divider />
        <li><LogoutLink/></li>
        
        
      </SideNav>
    )
  }
}

export default SideMenu