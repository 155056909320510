import React from "react"
import {
	Link,
} from "react-router-dom"
import {
	Breadcrumb,
} from "react-materialize"

export default ({links, history}) => {
	return (
		<Breadcrumb className="breadcrumbs transparent">
			{links.map((link, key) => (
				<Link
					key={`breadcrumb-${key}`}
					to={link.pathname}
					onClick={e => {
						e.preventDefault()
						if(history.location.pathname !== link.pathname) {
							history.push(link.pathname)
						}
					}}>
					{link.text}
				</Link>
			))}
		</Breadcrumb>
	)
}