import {
	CLIENT_SET,
	CLIENT_UNSET
} from "./constants"

export function setClient(user) {
  return {
    type: CLIENT_SET,
    user,
  }
}

export function unsetClient() {
  return {
    type: CLIENT_UNSET,
  }
}
