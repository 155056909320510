import React from "react"
import { connect } from "react-redux"
import {
  Route,
  Redirect,
} from "react-router-dom"

import {
  userHasRole
} from "components/views/auth/functions"

const ConditionedRoute = ({ client, authenticated, authRole, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      
      if(typeof authenticated === "undefined")
        return true;

      if(authenticated) {
        
        if(client.token) {
          if(!authRole || userHasRole(client.user, authRole))
            return true

          return <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        }

        return <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      } else {
        if(!client.token)
          return true

        return <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      }
      
    }}
  />
);

const mapStateToProps = state => ({
  client: state.client,
})

export default connect(mapStateToProps, {})(ConditionedRoute)
