import CryptoJS from "crypto-js"
import {
	isValidJson,
} from "services/functions"

const {
	REACT_APP_SCP,
	REACT_APP_SCP_EXPIRES_IN_DAYS,
} = process.env

class Storage {}

const FOREVER_EXPIRATION_DAYS = 365 * 40

Storage.prototype.getItem = (key) => {

	const keyEQ = key + "="
	const cookieArray = document.cookie.split(";")
	var clientData
	for(var i=0; i < cookieArray.length; i++) {
	  var cookie = cookieArray[i];
	  while(cookie.charAt(0) === " ") {
	  	cookie = cookie.substring(1, cookie.length);
	  } 

	  if(cookie.indexOf(keyEQ) === 0) {
	  	clientData = cookie.substring(keyEQ.length, cookie.length)
	  	break;
	  }
	}

	if(typeof clientData === "undefined") {
		return null
	}

	const descryptedClientData =  CryptoJS.AES.decrypt(clientData, REACT_APP_SCP).toString(CryptoJS.enc.Utf8)
	if(REACT_APP_SCP) {
		if(descryptedClientData.trim() !== "") {
			if(isValidJson(descryptedClientData)) {
				return JSON.parse(descryptedClientData)
			}

			return descryptedClientData
		}

		return descryptedClientData
	}

	if(isValidJson(clientData)) {
		return JSON.parse(clientData)

	}

	return clientData

}

Storage.prototype.setItem = (key, value, forever) => {

	const valueString = JSON.stringify(value)
	const valueStringEncrypted = CryptoJS.AES.encrypt(valueString, REACT_APP_SCP).toString()

	var days = 8;
	if(forever) {
		days = parseInt(FOREVER_EXPIRATION_DAYS, 10)
	} else if(!isNaN(parseInt(REACT_APP_SCP_EXPIRES_IN_DAYS, 10))) {
		days = REACT_APP_SCP_EXPIRES_IN_DAYS
	}
	
  var date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "; expires=" + date.toUTCString();

	document.cookie = REACT_APP_SCP
									? key + "=" + valueStringEncrypted + expires + "; path=/"
									: key + "=" + valueString + expires + "; path=/"
}

Storage.prototype.removeItem = (key) => {
	document.cookie = key + "=; Max-Age=-99999999;";  
}

export default new Storage()