import { t } from "ttag"
import { call, put, takeLatest } from "redux-saga/effects"

/* CONSTANTS */ 
import {
  CURRENT_PROGRAM_REQUEST,
  CURRENT_PROGRAM_SUCCESS,
  CURRENT_PROGRAM_ERROR,
  DAILY_TASKS_REQUEST,
  DAILY_TASKS_SUCCESS,
  DAILY_TASKS_ERROR,
  TASK_TOGGLE_REQUEST,
  TASK_TOGGLED,
  TASK_SHARE,
} from "./constants"

import {
  LOGOUT,
} from "components/login/constants"

import {
  apiDailyTasksGet,
  apiCurrentProgramTasksGet,
  apiCurrentProgramGet,
  apiTaskTogglePut,
  challengeStatsScoreboardGetApi
} from "services/api-helpers"

import {
  facebookShare,
}  from "services/external-api-helpers"


export function* currentProgramRequestFlow({token}) {
  let programResponse;
  let dailyTasksResponse;
  let programTasksResponse;
  let scoreboardResponse;

  try {

    programResponse = yield call(apiCurrentProgramGet, {token})

    if("undefined" === typeof programResponse.httpStatus) {

    	const {challengeId} = programResponse
      scoreboardResponse = yield challengeStatsScoreboardGetApi({token, challengeId});

      if("undefined" === typeof scoreboardResponse.httpStatus) {
      	yield put({
      		type: CURRENT_PROGRAM_SUCCESS,
      		program: {
      			...programResponse,
      			scoreboard: scoreboardResponse,
      		},
      	})
      } else {
      	yield put({ type: CURRENT_PROGRAM_SUCCESS, program: programResponse })
      }
      
      yield put({ type: DAILY_TASKS_REQUEST })

      dailyTasksResponse = yield call(apiDailyTasksGet, {token})
      programTasksResponse = yield call(apiCurrentProgramTasksGet, {token})
      if("undefined" === typeof dailyTasksResponse.httpStatus) {
        yield put({ type: DAILY_TASKS_SUCCESS, tasks: dailyTasksResponse, tasksUptill: programTasksResponse })

      } else {
        yield put({ type: DAILY_TASKS_ERROR, errors: [{key: dailyTasksResponse.message}]})
      }
      
      
    } else {
      
      if(programResponse.httpStatus === 401) {
        yield put({type: LOGOUT})
      } else {
        yield put({ type: CURRENT_PROGRAM_ERROR, errors: [{key: programResponse.message}] })
      }
    }

  } catch (error) {
    yield put({ type: CURRENT_PROGRAM_ERROR, errors: [{key: t`Network error`}] })
  }

  return programResponse
}

/* TASK COMPLETE/UNCOMPLETE */
  function* taskToggleFlow({ taskTogglePayload }) {
    let taskToggleResponse
    try {
      taskToggleResponse = yield call(apiTaskTogglePut, taskTogglePayload)
      if("undefined" === typeof taskToggleResponse.httpStatus) {

        yield put({ type: TASK_TOGGLED, task: taskToggleResponse })
      }

    } catch (error) {

    }

    return taskToggleResponse
  }

/* TASK SHARE */ 
  function* taskShareFlow({ shareLinkContent, callback }) {
    let taskShareResponse
    try {
      const fbsdResponse = yield call(facebookShare, shareLinkContent)
      if(!fbsdResponse.isCancelled)
        if("undefined" !== typeof callback)
          yield call(callback)

    } catch (error) {
      //
    }

    return taskShareResponse
  }
export default function* DailyTasksWatcher () {
  yield takeLatest(CURRENT_PROGRAM_REQUEST, currentProgramRequestFlow)
  yield takeLatest(TASK_TOGGLE_REQUEST, taskToggleFlow)
  yield takeLatest(TASK_SHARE, taskShareFlow)
}
