import {
  CHALLENGE_GET_SUCCESS,
  CHALLENGE_GET_ERROR,
  CHALLENGE_EDIT,
  CHALLENGE_EDIT_CLEAR,
  CHALLENGE_UPDATE_REQUEST,
  CHALLENGE_UPDATE_SUCCESS,
  CHALLENGE_UPDATE_ERROR,
  CHALLENGE_ACTIVATE_TOGGLE_SUCCESS,
  CHALLENGE_TASKS_GET_SUCCESS,
} from "../constants"

const initialState = {
  unauthorized: false,
  requesting: false,
  success: false,
  errors: [],
  challenge: null,
  tasks: null,
  loaded: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHALLENGE_GET_SUCCESS:
      return {
      	...state,
      	challenge: action.challenge,
      	loaded: true,
      }

    case CHALLENGE_GET_ERROR:
      return {
      	...state,
        unauthorized: true,
      }

    case CHALLENGE_EDIT:
      return {
      	...state,
      	challenge: action.challenge,
      }

    case CHALLENGE_EDIT_CLEAR:
      return {
      	...initialState,
      	loaded: false,
      }

    case CHALLENGE_UPDATE_REQUEST:
      return {
      	...state,
        requesting: true,
        success: false,
      }

    case CHALLENGE_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        errors: [],
        requesting: false,
        success: true,
      })

    case CHALLENGE_UPDATE_ERROR:
      return Object.assign({}, state, {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      })

    case CHALLENGE_ACTIVATE_TOGGLE_SUCCESS:
      return Object.assign({}, state, {
        challenge: action.challenge
      })

    case CHALLENGE_TASKS_GET_SUCCESS:
      return Object.assign({}, state, {
        tasks: action.tasks.map(task => Object.assign({}, task, {answers: task.answers ? JSON.parse(task.answers) : null} )),
      })

    default:
      return state
  }
}