import {
  CHALLENGE_CREATE_REQUEST,
  CHALLENGE_CREATE_SUCCESS,
  CHALLENGE_CREATE_ERROR,
  CHALLENGE_CREATE_CLEAR,
} from "../constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHALLENGE_CREATE_REQUEST:
      return {
        requesting: true,
        success: false,
        errors: [].concat(state.errors),
      }

    case CHALLENGE_CREATE_SUCCESS:
      return {
        errors: [],
        requesting: false,
        success: true,
        challengeId: action.challengeId
      }

    case CHALLENGE_CREATE_ERROR:
      return {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      }

    case CHALLENGE_CREATE_CLEAR:
      return initialState

    default:
      return state
  }
}