import React from "react"
import { t } from "ttag"
import {
	Icon,
} from "react-materialize"

export default ({logoutAction, className}) => (
	<a
		href="#logout" className={className ? className : ""}
		onClick={e => {
			e.preventDefault()
			logoutAction(0)
		}}>
		<Icon>power_settings_new</Icon> {t`Logout`}
	</a>
)