import React from "react"
import { t } from "ttag"
import {
  Row,
} from "react-materialize"

export default ({scoreboard, user}) => (
  <Row>
  	{!!scoreboard.length && (
	    <table className="highlight striped challenge-scoreboard">
	      <thead>
	        <tr>
	          <th>{t`Name`}</th>
	          <th>{t`Points`}</th>
	        </tr>
	      </thead>
	      <tbody>
	      	{scoreboard.map((contestant, key) => (
	    			<tr key={key} className={!!user && contestant.userId === user.id ? 'active' : ''}>
	            <td>
	            	{contestant.name}
	            </td>
	            <td>{contestant.points}</td>
	          </tr>
	        ))}
	      </tbody>
	    </table>
  	)}
    {!scoreboard.length && (
    	<div>
      	{t`Scoreboard is empty`}
      </div>
    )}
  </Row>
)