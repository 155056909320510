import React from "react"

import IosArrowDown from "react-ionicons/lib/IosArrowDown"
import IosArrowUp from "react-ionicons/lib/IosArrowUp"

const ChallengeDayTasksHeading = ({labels, day, isDayCollapsed, onTogglePress}) => {
	const ArrowIcon = isDayCollapsed ? IosArrowUp : IosArrowDown
  return (
    <button href="toggle" className="challenges_form-days-task_list_item toggle" onClick={onTogglePress}>
      <span className="challenges_form-label">{labels.Day + " " + day}</span> <ArrowIcon fontSize="16px"/>
    </button>
  )
}

export default ChallengeDayTasksHeading