import {
  CHALLENGE_STATS_USER_TASKS_REQUEST,
  CHALLENGE_STATS_USER_TASKS_CLEAR,
  CHALLENGE_STATS_USER_TASKS_FILTER_SET,
  CHALLENGE_STATS_USER_TASKS_COMPLETED_TOGGLE,
} from "./constants"

export function challengeStatsUserTasksRequestAction({ token, challengeId, userId, callback }) {
	return {
    type: CHALLENGE_STATS_USER_TASKS_REQUEST,
    token,
    challengeId,
    userId,
    callback,
  }
}

export function challengeStatsUserTasksClearAction() {
  return {
    type: CHALLENGE_STATS_USER_TASKS_CLEAR,
  }
}

export function challengeStatsUserTasksFilterSet({q}) {
	return {
		type: CHALLENGE_STATS_USER_TASKS_FILTER_SET,
		q,
	}
}

export function challengeStatsUserTasksCompletedToggle({completed}) {
	return {
		type: CHALLENGE_STATS_USER_TASKS_COMPLETED_TOGGLE,
		completed,
	}
}