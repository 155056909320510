export const CHALLENGE_TASKS_GET_REQUEST = "CHALLENGE_TASKS_GET_REQUEST"
export const CHALLENGE_TASKS_GET_SUCCESS = "CHALLENGE_TASKS_GET_SUCCESS"
export const CHALLENGE_TASKS_GET_ERROR = "CHALLENGE_TASKS_GET_ERROR"

export const CHALLENGE_TASKS_CREATE_REQUEST = "CHALLENGE_TASKS_CREATE_REQUEST"
export const CHALLENGE_TASKS_CREATE_SUCCESS = "CHALLENGE_TASKS_CREATE_SUCCESS"
export const CHALLENGE_TASKS_CREATE_ERROR = "CHALLENGE_TASKS_CREATE_ERROR"

export const CHALLENGE_TASKS_UPDATE_REQUEST = "CHALLENGE_TASKS_UPDATE_REQUEST"
export const CHALLENGE_TASKS_UPDATE_SUCCESS = "CHALLENGE_TASKS_UPDATE_SUCCESS"
export const CHALLENGE_TASKS_UPDATE_ERROR = "CHALLENGE_TASKS_UPDATE_ERROR"

export const CHALLENGE_TASKS_DELETE_REQUEST = "CHALLENGE_TASKS_DELETE_REQUEST"
export const CHALLENGE_TASKS_DELETE_ERROR = "CHALLENGE_TASKS_DELETE_ERROR"

export const CHALLENGE_TASK_EDIT = "CHALLENGE_TASK_EDIT"
export const CHALLENGE_TASK_EDIT_CLEAR = "CHALLENGE_TASK_EDIT_CLEAR"
export const CHALLENGE_DAY_TASK_CREATE = "CHALLENGE_DAY_TASK_CREATE"
export const CHALLENGE_DAY_TASK_CREATE_CLEAR = "CHALLENGE_DAY_TASK_CREATE_CLEAR"

export const CHALLENGE_TASK_CHANGE = "CHALLENGE_TASK_CHANGE"
export const CHALLENGE_TASK_DAYS_COLLAPSE_TOGGLE = "CHALLENGE_TASK_DAYS_COLLAPSE_TOGGLE"

export const CHALLENGE_TASK_ANSWER_ADD = "CHALLENGE_TASK_ANSWER_ADD"
export const CHALLENGE_TASK_ANSWER_REMOVE = "CHALLENGE_TASK_ANSWER_REMOVE"
export const CHALLENGE_TASK_ANSWER_CORRECT_SET_INDEX = "CHALLENGE_TASK_ANSWER_CORRECT_SET_INDEX"
