/* REDUX */
import { connect } from "react-redux"

import {
	challengeCreateAction,
	challengeCreateClear,
} from "../actions"

/* THE COMPONENT */
import ChallengesCreate from "./component" 

const mapStateToProps = state => ({
  token: state.client.token,
  challengeCreated: state.challengeCreated,
})

const actions = {
	challengeCreateAction,
	challengeCreateClear,
}

export default connect(mapStateToProps, actions)(ChallengesCreate)