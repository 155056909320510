import React from "react"
import {
	gettext
} from "ttag"
import {
	Select,
} from "react-materialize"

export default ({task, taskCategories, taskInputTypes, labels, onChangeHandler }) => (
	<div className={"task_input_col " + (taskCategories ? "col-xs-8 col-md-3" : "col-xs-12 col-md-5")}>
      <Select
      	s={12}
      	m={12}
      	l={12}
      	label={gettext(labels.TaskCompletion)}
      	onChange={onChangeHandler}
      	data-key="inputType"
      	value={task.inputType}
      	className="form-control">
        {taskInputTypes.map((option) => (
        	<option
        		key={task.id + "-" + option.value}
        		value={option.value}>
        		{gettext(option.label)}
        	</option>
        ))}
      </Select>
  </div>
)