/* REDUX SAGA EFFECTS */ 
import { call, put, takeLatest } from "redux-saga/effects"

import Storage from "services/storage"

import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
} from "./constants"

import {
  setClient,
} from "client/actions"

import {
  registerApi,
  loginApi
} from "services/api-helpers"

function* registerFlow(data) {

  let registerResponse
  try {
    
    registerResponse = yield call(registerApi, data)
    if(registerResponse.httpStatus) {
      yield put({ type: REGISTER_ERROR, errors: [{key: registerResponse.message}] })
      return
      
    }

    let loginResponse = yield call(loginApi, {email: data.email, password: data.password})
    if("undefined" !== typeof loginResponse.httpStatus) {
      yield put({ type: REGISTER_ERROR, errors: [{key: loginResponse.message}] })
      return
    }

    yield call(handleRegisterSuccess, loginResponse, data.cb)
    

  } catch (error) {
    yield put({ type: REGISTER_ERROR, errors: [{key: "Network error"}] })

  } finally {

  }

  return registerResponse
}

function* handleRegisterSuccess(resp, cb) {
  yield put(setClient(resp))
  yield put({ type: REGISTER_SUCCESS })
  yield Storage.setItem(process.env.REACT_APP_CLIENT_AUTH_KEY, resp)

  if(typeof cb !== "undefined") {
    yield call(cb)
  }
}

export default function* RegisterWatcher () {
  yield takeLatest(REGISTER_REQUEST, registerFlow)
}
