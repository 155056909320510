import React from "react"
import { t } from "ttag"
import {
  Row,
  Col,
  Card,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"

/* MOMENT */
import moment from "moment"

/* COMPONENTS */
import LoadingSpinner from "components/loading-spinner"
import {
	TasksHeader,
	TaskList,
	DayChangeNavigation,
} from "./components"

import {
  parseTaskTogglePayload
} from "services/functions"

export default class DailyTasks extends React.Component {

  componentDidMount() {
    const { program } = this.props

    if(!program.loaded && !program.program) {
      this.handleRefreshDailyTasks()
    }
  }

  handleRefreshTasksTab = () => {
    const { tasks } = this.props.tasks
    if(!tasks || !tasks.length)
      return

    if(tasks[0].day !== moment().format("YYYY-MM-DD")) {
      this.handleRefreshDailyTasks()
    }
  }

  handleRefreshDailyTasks = () => {
    const { client } = this.props
    this.props.currentProgramAction(client)
    
  }

  handleTaskToggle = (task) => {
    const { program } = this.props.program
    const { token } = this.props.client
    const payload = parseTaskTogglePayload(program, task, token)
    this.props.taskToggleAction({taskTogglePayload: payload})
  }

  handleTaskInputChange = (task) => {
    this.props.taskInputChangeAction({ task })
  }

  handleTaskVideoPlayingToggle = (task) => {
    this.props.taskVideoPlayingToggleAction({ task })
  }

  handleTaskVideoPlaybackStarted = (task) => {
    this.props.taskVideoPlaybackStartedAction({ task })
  }

  getPlayingTaskVideo = () => {
    const { tasks } = this.props

    if(!tasks.loaded && tasks.requesting) {
      return false
    }

    if(!tasks.tasks || !tasks.tasks.length) {
      return false;
    }

    const playingTasks = tasks.tasks.filter(task => task.playing);
    if(!playingTasks.length) {
      return false;
    }

    return playingTasks[0]
  }

  render() {

    const { program } = this.props
    if(!program.loaded && program.requesting) {
      return <LoadingSpinner />
    }

    return (
      <Row>
		  	<Breadcrumbs links={[
		  		{pathname: "/", text: t`My daily tasks`},
		  	]}/>

				<Row>
					<Col
						offset="m1 l1 xl1"
						s={12}
						m={10}
						l={10}
						xl={10}>
						<Card
					  	className="animate fadeLeft card-nopad app-card">
			        
			        <DayChangeNavigation { ...this.props}/>
			        <TasksHeader {...this.props} />
			        <TaskList
			          {...this.props}
			          handleTaskToggle={this.handleTaskToggle}
			          handleTaskInputChange={this.handleTaskInputChange}
			          handleTaskVideoPlayingToggle={this.handleTaskVideoPlayingToggle} />
			      </Card>
					</Col>
				</Row>

		  </Row>
    )
  }
}