import React from "react"
import {
	gettext
} from "ttag"

import IosArrowDown from "react-ionicons/lib/IosArrowDown"

const InputTaskCategories = ({task, labels, onChangeHandler, taskCategories }) => (
	<div className="task_input_col col-xs-4 col-md-2">
    <label>{gettext(labels.TaskTime)}</label>
    <div className="select-block">
      <select onChange={onChangeHandler} data-key="category" value={task.name.category} className="form-control">
        {taskCategories.map((option) => <option key={task.id + "-" + option.value} value={option.value}>{gettext(option.label)}</option>)}
      </select>
      <IosArrowDown fontSize="16px" />
    </div>
  </div>
)

export default InputTaskCategories