import { all } from "redux-saga/effects"
import LoginWatcher from "components/login/sagas"
import ForgotPasswordWatcher from "components/forgot-password/sagas"
import RegisterWatcher from "components/register/sagas"
import DailyTasksWatcher from "components/daily-tasks/sagas"
import ChangePasswordWatcher from "components/change-password/sagas"
import ChallengesWatcher from "components/challenges/sagas"
import ChallengeStatsDaysWatcher from "components/challenges/dashboard/components/days/sagas"
import ChallengeStatsUsersWatcher from "components/challenges/dashboard/components/users/sagas"
import ChallengeStatsUserTasksWatcher from "components/challenges/dashboard/components/users/tasks/sagas"
import ReferralWatcher from "components/referral/sagas"

export default function* RootSaga() {
	yield all([
		LoginWatcher(),
		RegisterWatcher(),
		DailyTasksWatcher(),
		ForgotPasswordWatcher(),
		ChangePasswordWatcher(),
		ChallengesWatcher(),
		ChallengeStatsDaysWatcher(),
		ChallengeStatsUsersWatcher(),
		ChallengeStatsUserTasksWatcher(),
		ReferralWatcher(),
	])
}