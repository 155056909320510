/* REDUX */
import { connect } from "react-redux"

/* THE COMPONENT */
import AccountProfile from "./component" 

const mapStateToProps = state => ({
  user: state.client.user,
})

export default connect(mapStateToProps, null) (AccountProfile)