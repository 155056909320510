import {
  CHALLENGES_GET_REQUEST,
  CHALLENGES_GET_SUCCESS,
  CHALLENGES_GET_ERROR,
} from "../constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
  challenges: [],
  loaded: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHALLENGES_GET_REQUEST:
      return {
      	...state,
        requesting: true,
      }

    case CHALLENGES_GET_SUCCESS:
      return {
      	...state,
        requesting: false,
        success: true,
        challenges: action.challenges,
        errors: [],
        loaded: true,
      }

    case CHALLENGES_GET_ERROR:
      return {
      	...state,
        requesting: false,
        errors: [].concat(action.errors),
        loaded: true,
      }

    default:
      return {
      	...state,
      }
  }
}