import React from "react"
import { t } from "ttag"
import MdCheckmarkCircleOutline from "react-ionicons/lib/MdCheckmarkCircleOutline"
import MdRadioButtonOff from "react-ionicons/lib/MdRadioButtonOff"

export default ({challenge, handleToggleActivateChallenge}) => {

	const {active} = challenge
	return (
		<div className="app-form-group">
			<div className={"challenge-activator" + (active ? " active" : "")}>
	      <a href="challenge-activator" onClick={e => {e.preventDefault(); handleToggleActivateChallenge(challenge.id)}}>{active ? <MdCheckmarkCircleOutline color="#fff" /> : <MdRadioButtonOff color="#0195ff" />} {active ? t`Challenge is active` : t`Challenge is inactive`}</a>
	    </div>
	  </div>
	)
}