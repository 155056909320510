/* REDUX/SAGA */
import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"

/* ROOT REDUCER/SAGA */
import RootReducer from "./root-reducer"
import RootSaga from "./root-saga"
const sagaMiddleware = createSagaMiddleware()
const ReduxStore = createStore(RootReducer, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(RootSaga)

export default ReduxStore