import {
  CURRENT_PROGRAM_REQUEST,
  CURRENT_PROGRAM_SUCCESS,
  CURRENT_PROGRAM_ERROR,
  CURRENT_PROGRAM_CLEAR,
} from "../constants"

const initialStateProgram = {
  program: null,
  requesting: false,
  success: false,
  loaded: false,
}

const program = function programReducer(state = initialStateProgram, action) {
  switch (action.type) {
    case CURRENT_PROGRAM_REQUEST:
      return {
      	...state,
        program: null,
        requesting: true,
      }

    case CURRENT_PROGRAM_SUCCESS:
      return {
      	...state,
        program: {
        	...action.program,
        },
        requesting: false,
        success: true,
        loaded: true,
      }

    case CURRENT_PROGRAM_ERROR:
      return {
      	...state,
        program: null,
        requesting: false,
        loaded: true,
      }

    case CURRENT_PROGRAM_CLEAR:
      return {
      	...state,
        program: null,
        requesting: false,
        success: false,
        loaded: false,
      }

    default:
      return state
  }
}

export default program