/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	forgotPasswordRequestAction,
	forgotPasswordClearAction,
	forgotPasswordValidationErrorAction
} from "./actions"

/* THE COMPONENT */
import ResetPassword from "./component" 

const mapStateToProps = state => ({
  forgotPassword: state.forgotPassword,
})

const actions = {
	forgotPasswordRequestAction,
	forgotPasswordClearAction,
	forgotPasswordValidationErrorAction,
}

export default connect(mapStateToProps, actions)(ResetPassword)