/* REDUX */
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
	challengesGetAction,
	challengeDeleteAction,
} from "../actions"

/* THE COMPONENT */
import ChallengesList from "./component" 

const mapStateToProps = state => ({
  token: state.client.token,
  challengesList: state.challengesList,
})

const actions = {
	challengesGetAction,
	challengeDeleteAction,
}

const connected = connect(mapStateToProps, actions)(ChallengesList)
export default withRouter(connected)