import React from "react"
import ReactPlayer from "react-player"
import { t } from "ttag"

/* COMPONENTS */ 
import FacebookShareButton from "components/facebook-share-task-button"
import MdSquareOutline from "react-ionicons/lib/MdSquareOutline"
import MdCheckboxOutline from "react-ionicons/lib/MdCheckboxOutline"
import IosQuote from "react-ionicons/lib/IosQuote"

const TaskName = ({ name, completed, handleTaskToggle }) =>
  <a href={name} onClick={handleTaskToggle}>{name}</a>

const VideoPlayer = ({ handleTaskVideoPlayingToggle, url, playing, completed }) => (
  <div className="program-task-video-play">
    <div className="program-task-video-play-button">
    	<div className="react-player-wrapper">
        <ReactPlayer
					className={"react-player"}
          width="100%"
          height="100%"
					url={url}
					onStart={handleTaskVideoPlayingToggle}
					controls/>
			</div>
    </div>
  </div>
)

const FacebookShareTask = ({ task, handleTaskToggle }) => (
  <div>
    <FacebookShareButton shareLinkContentUrl={task.fbshare} completed={task.completed}/>
  </div>
)


export default class TaskItem extends React.Component {

  handleTaskToggle = e => {
    e.preventDefault()

    const { item } = this.props
    if(!item.completed && item.inputType === "TEXT" && (!item.inputValue || item.inputValue === "")) {
      this.textInput.focus()
      return
    }
    
    this.props.handleTaskToggle(item)
  }

  handleTaskVideoPlayingToggle = () => {
    const { youtube } = this.props.item
    if(youtube)
      this.props.handleTaskVideoPlayingToggle(this.props.item)
  }

  handleTaskInputChange = (e) => {
    const { item } = this.props
    const newItem = Object.assign({}, item, {inputValue: e.target.value})
    this.props.handleTaskInputChange(newItem)
  }

  renderTaskName = () => {
    const { name, completed, youtube, vimeo, playing, fbshare } = this.props.item
    if(youtube) {
      return <VideoPlayer {...{completed, playing, url: `https://www.youtube.com/embed/${youtube}?autoplay=true`}} handleTaskVideoPlayingToggle={this.handleTaskVideoPlayingToggle}/>
    }

    if(vimeo) {
    	return <VideoPlayer {...{completed, playing, url: `https://vimeo.com/${vimeo}`}} handleTaskVideoPlayingToggle={this.handleTaskVideoPlayingToggle}/>
    }

    if(fbshare) {
      return <FacebookShareTask task={this.props.item} handleTaskToggle={this.handleTaskToggle} />
    }

    return (
      <div className={"program-task-text" + (completed ? " completed" : "")}>
        <TaskName {...{name, youtube, vimeo, completed}} handleTaskToggle={this.handleTaskToggle}/>
      </div>
    )

  }

  render() {
    const { completed, inputType, inputValue } = this.props.item
    return (
      <div className="program-task-row">
        <div className="program-task-item clear">
          <button className="program-task-toggle" onClick={this.handleTaskToggle}>
            {completed ? <MdCheckboxOutline fontSize="22px" color="#ccc"/> : <MdSquareOutline fontSize="22px"/>}
          </button>
          {this.renderTaskName()}
        </div>
        {inputType === "TEXT" && 
        <div className="program-task-item clear">
          <button className="program-task-toggle" onClick={() => this.textInput.focus()}>
            <IosQuote fontSize="22px" color={completed ? "#ccc" : "#000"}/>
          </button>
          {completed && <div className="program-task-input-completed">{inputValue}</div>}
          {!completed && <textarea rows={5} ref={textInput => this.textInput = textInput} placeholder={t`Please add text...`} onChange={this.handleTaskInputChange}>{inputValue}</textarea>}
        </div>}
      </div>
    )
  }

}