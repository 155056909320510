import { connect } from "react-redux"
import { withRouter } from "react-router-dom"  

import Dashboard from "./component"

import {
	challengeGetAction,
	challengeEditedClearAction,
} from "components/challenges/actions"

const mapStateToProps = state => ({
	token: state.client.token,
  challengeEdited: state.challengeEdited,
})

const action = {
	challengeGetAction,
	challengeEditedClearAction,
}


export default withRouter(connect(mapStateToProps, action)(Dashboard))