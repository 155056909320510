import React from "react"
import MdTrash from "react-ionicons/lib/MdTrash"
import ReactPlayer from "react-player"

import {
  parseShortcode,
} from "services/functions"

const labels = {
  Day: `Day`,
  CreateTask: `Create task`,
  SavedOk: `Saved`,
  Task: `Task`,
  PointsForCompletion: `Points for completion`,
  Save: `Save`,
  AreYouSureToRemoveThisTask: `Do you really want to remove this task?`,
  WatchYoutubeVideo: `Watch youtube video`,
  WatchVimeoVideo: `Watch vimeo video`,
  ShareLinkOnFacebook: `Share link on facebook`,
  TaskCompletion: `Task completion`,
}

export default ({task, clearTaskNameEdited}) => {
  
  const shortCodes = parseShortcode(task.name);
  if("undefined" !== typeof shortCodes["youtube"]) {
    return (
      <div className="task-type_special">
        <div className="type_special_wrap">{labels.WatchYoutubeVideo} {clearTaskNameEdited && <button className="remove-type_special" onClick={clearTaskNameEdited}><MdTrash fontSize="16px"/></button>}
        </div>
        <div className="react-player-wrapper">
	        <ReactPlayer
						className={"react-player"}
	          width="100%"
	          height="100%"
						url={`https://www.youtube.com/watch?v=${shortCodes["youtube"]}`}
						controls/>
				</div>
      </div>
    )
  }

	if("undefined" !== typeof shortCodes["vimeo"]) {
    return (
      <div className="task-type_special">
        <div className="type_special_wrap">{labels.WatchVimeoVideo} {clearTaskNameEdited && <button className="remove-type_special" onClick={clearTaskNameEdited}><MdTrash fontSize="16px"/></button>}
        </div>
        <div className="react-player-wrapper">
	        <ReactPlayer
						className={"react-player"}
	          width="100%"
	          height="100%"
						url={`https://vimeo.com/${shortCodes["vimeo"]}`}
						controls/>
				</div>
      </div>
    )
  } 

  if("undefined" !== typeof shortCodes["fbshare"])
    return (
       <div className="task-type_special">
        <div className="type_special_wrap">{labels.ShareLinkOnFacebook} {clearTaskNameEdited && <button href={`/[fbshare ${shortCodes["fbshare"]}]`} className="remove-type_special" onClick={clearTaskNameEdited}><MdTrash fontSize="16px"/></button>}
        </div>
        <div className="type_special_wrap"><a target="_blank" rel="noopener noreferrer" href={shortCodes["fbshare"]}>{shortCodes["fbshare"]}</a></div>
       </div>
    );

}