export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"
export const CHANGE_PASSWORD_CLEAR = "CHANGE_PASSWORD_CLEAR"
export const CHANGE_PASSWORD_VALIDATION_ERROR = "CHANGE_PASSWORD_VALIDATION_ERROR"
export const FIELDS = [
	{
		type: "password",
		name: "currentPassword",
		label: "Terajšie heslo",
		icon: "lock_outline",
		grid: {
			s: 12,
		},
		required: true,
		error: "Vpíšte prosím Vaše terajšie heslo.",
	},
	{
		type: "password",
		name: "newPassword",
		label: "Nové heslo",
		icon: "lock_outline",
		grid: {
			s: 12,
		},
		required: true,
		error: "Zvoľte prosím Vaše nové heslo.",
	},
	{
		type: "password",
		name: "newPasswordConfirm",
		label: "Zopakovať nové heslo",
		icon: "lock_outline",
		grid: {
			s: 12,
		},
		required: true,
		error: "Zopakujte prosím Vaše nové heslo.",
	},
]
