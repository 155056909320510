import latinize from "latinize"
import history from "client/history"
import queryString from "query-string"

export function arrayContains(a, obj) {
	if(!a)
		return false;

  var i = a.length;
  while (i--)
    if (a[i] === obj)
      return true;
  
  return false;
}

export function classParent(target, className, first) {
  if(!arrayContains(target.classList, className)) {
    if(!first && !target.parentNode) return false;

    return classParent(target.parentNode, className);

  } else {
    return target;

  }
}

export function preventSubmitByEnter(e) {
	if(e.key === 'Enter') e.preventDefault();
}

export function matchValidUrl(url) {
	const regExp = new RegExp(/[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/)
	const match = url.match(regExp)
  if(match && match[0])
    return match[0]

  return false
}

export function matchYoutubeVideoUrl(url) {
	const regExp = new RegExp(/(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/)
	const match = url.match(regExp)
  if(match && match[1] && match[1].length === 11) {
    return match[1]
  }

  return false
}

export function matchVimeoVideoUrl(url) {
	const regExp = new RegExp(/(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/)
	const match = url.match(regExp)
  if(match && match[4]) {
    return match[4]
  }

  return false
}

export function matchShortcode(string) {
	const regExp = new RegExp(/\[([^\]\s].+)\s([^\s].*)\]/)
	const match = regExp.exec(string)
	if(!match || match.length < 3)
		return false

	return match
}

export function parseShortcode(string) {

	const shortCodeMatch = matchShortcode(string)
	if(shortCodeMatch) {
		let shortCodeObject = {}
		shortCodeObject[shortCodeMatch[1]] = shortCodeMatch[2]

		return Object.assign({}, shortCodeObject)
	}

	const youtubeVideoIdMatch = matchYoutubeVideoUrl(string)
	if(youtubeVideoIdMatch) {
		return Object.assign({}, {youtube: youtubeVideoIdMatch})
	}

	const vimeoVideoId = matchVimeoVideoUrl(string)
	if(vimeoVideoId) {
		return Object.assign({}, {vimeo: vimeoVideoId})
	}

	return {}

}

export function parseTaskTogglePayload(program, task, token) {
  return Object.assign({}, {
    programId: program.id,
    token: token,
    taskId: task.id,
    inputValue: task.inputValue,
    action: task.completed ? "uncomplete" : "complete"
  })
}

export function route(name, params) {
  const host = window.location.origin

  switch(name) {
    case "challenges.accept":
      var route = host + "/challenges/:challengeId/accept";
      if(typeof params === "undefined" || !params.length)
        return route

      return route.replace(':challengeId', params[0])

    default:
      return host
  }
}

export function getItemByObjKey(arr, key, value, returnKey) {
  if(!arr || !arr.length)
    return false

  const arrFiltered = arr.filter(item => item[key] === value)
  if(!arrFiltered.length)
    return false;

  if(returnKey) 
    return arrFiltered[0][returnKey]

  return arrFiltered[0]
}

export function searchFilterItems({q, items, searchKeys}) {

	if(!q) {
		return [].concat(items)
	}

	const qArr = q.split(" ").filter(item => item !== "")
	if(!qArr.length) {
		return [].concat(items)
	}

	return items.filter((item, key) => {
		for(var s=0; s < qArr.length; s += 1) {
			var qS = qArr[s]
			var sqMatch = false

			for(var i=0; i < searchKeys.length; i += 1) {

				var sKeyAttrs = searchKeys[i].split(".")
				var itemSearchAttr = item
				for(var sa=0; sa < sKeyAttrs.length; sa+=1) {
					if(!itemSearchAttr[sKeyAttrs[sa]]) {
						continue
					}
					itemSearchAttr = itemSearchAttr[sKeyAttrs[sa]];
				}

		    if(new RegExp(latinize(qS), 'gi').test(latinize(itemSearchAttr))) {
		    	sqMatch = true
		    	break
		    }

		    if(new RegExp(qS, 'gi').test(itemSearchAttr)) {
		      sqMatch = true
		      break
		    }

			}

			if(!sqMatch) {
				return false
			}
		}

		return true
			
	})
}

export function queryParam(name, url) {
  if (!url) {
    url = window.location.href;
  }

  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isValidJson(jsonString) {

	var isValidJSON = true
	try {
		JSON.parse(jsonString)
	} catch {
		isValidJSON = false
	}

	return isValidJSON
}

export function stripQueryParam(key, url) {

	if (!url) {	
    url = window.location.href;
  }

  const queryStringParams = queryString.parse(window.location.search)
  const param = queryStringParams[key]
  if(!param) {
  	return false
  }

  const originPathname = `${window.location.pathname}`
  const qso = {
  	...queryStringParams,
  }
  delete qso[key]
  var qs = Object.keys(qso).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(qso[k])}`).join('&')

  if(qs === "") {
  	history.replace(originPathname)
  } else {
  	history.replace(`${originPathname}?${qs}`)
  }
}

export function getTaskCategoryDescriptionValue(tasks) {
	if(!tasks.length) {
		return null
	}

	let filteredTasks = tasks.filter(task => task.category === 'DESCRIPTION')
	if(!filteredTasks.length) {
		return null
	}

	return filteredTasks[0].description
}