import React from "react"

import {
  Switch,
  Route
} from "react-router-dom"

import DailyTasks from "components/daily-tasks"
import DailyTasksScoreboard from "components/daily-tasks-scoreboard"

import ChallengeUserTasks from "components/challenges/dashboard/components/users/tasks"
import ChallengeDashboard from "components/challenges/dashboard"
import ChallengesEdit from "components/challenges/edit"
import ChallengesCreate from "components/challenges/create"
import ChallengesList from "components/challenges/list"

import AccountProfile from "components/account"
import ChangePassword from "components/change-password"

export default ({authenticated}) => {

	if(!authenticated) {
		return false
	}

	return (
		<div className="home">
			<Switch>
				
				<Route exact path="/challenges/:challengeId/tasks/:userId" component={ChallengeUserTasks}/>
	      <Route exact path="/challenges/:challengeId/dashboard" component={ChallengeDashboard}/>
	      <Route exact path="/challenges/:challengeId/edit" component={ChallengesEdit}/>
	      <Route exact path="/challenges/create" component={ChallengesCreate}/>
	      <Route exact path="/challenges" component={ChallengesList}/>
	      
	      <Route exact path="/account/change-password" component={ChangePassword}/>
	      <Route exact path="/account" component={AccountProfile}/>
	      <Route exact path="/scoreboard" component={DailyTasksScoreboard}/>
	      <Route exact path="/" component={DailyTasks}/>
		  </Switch>
		</div>
	)
}