import React from "react"
import { t } from "ttag"
import {
	Button,
	Icon,
} from "react-materialize"

/* VENDOR PACKAGES */
import moment from "moment"

const DayChangeNavigation = ({tasks, program, dayMoveBackAction, dayMoveForwardAction}) => {

  if(!dayMoveBackAction && !dayMoveForwardAction)
    return false;

  if(!program.program)
    return false;

  const now = moment().format('YYYY-MM-DD')
  const canMoveForward = tasks.currentDay < now
  const canMoveBack = program.program.firstDay < tasks.currentDay

	return (
		<div className="app-card-head app-card-head-2250 app-card-header-flex clear">
			{(canMoveBack && dayMoveBackAction) ? (
				<Button
					onClick={() => dayMoveBackAction({program})}
					className="btn-plain flex-start left-icon">
					<Icon left>arrow_back_ios</Icon> {t`Back`}
				</Button>
			) : (
				<span className="flex-start"></span>
			)}
      {tasks.currentDay && <span className="flex-center">{moment(tasks.currentDay).format('D/M/YY')}</span>}
      {(canMoveForward && dayMoveForwardAction) ? (
      	<Button
      		onClick={() => dayMoveForwardAction({program})}
      		className="btn btn-plain flex-end right-icon">
      			{t`Forward`} <Icon right>arrow_forward_ios</Icon>
      	</Button>
      ) : (
	      <span className="flex-end"></span>
      )}
	  </div>
	)
}

export default DayChangeNavigation