import React from "react"
import { t } from "ttag"
import {
  Card,
} from "react-materialize"

/* COMPONENTS */
import { ChallengeScoreboard } from "components/challenges/components"

export default ({scoreboard}) => (
	<Card
  	className="subscriber-list-card animate fadeRight"
    title={t`Scoreboard`}>
    <ChallengeScoreboard scoreboard={scoreboard}/>
  </Card>
)