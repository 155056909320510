import React from "react"
import { t } from "ttag"
import {
  Row,
  Col,
  Card,
  TextInput,
  Checkbox,
} from "react-materialize"

/* COMPONENTS */
import FormErrors from "components/form-errors"
import FormActionButton from "components/form-action-button"
import FormSubAction from "components/form-subaction"
import {
	LanguageSwitcher,
} from "components/locale"

class Login extends React.Component {

  state = {
    email: "",
    password: "",
  }

  componentWillUnmount() {
    this.props.loginClearAction()
  }
  
  signIn = () => {
    
    const {requesting, success, cb} = this.props
    if(requesting || success)
      return

    this.props.loginRequestAction({
      email: this.state.email,
      password: this.state.password,
      cb: cb,
    })
    
  }

  render() {
    
    const { errors, requesting } = this.props.login
    const {
    	authenticated,
    	onClickToSignup,
    	onClickToResetPassword,
    	submitButtonTextOverride,
    	header,
    } = this.props

    if(authenticated) {
      return false
    }

    var resetPasswordMarkup = false
    resetPasswordMarkup = <Col className="input-field right-align" s={6}><FormSubAction onClick={onClickToResetPassword} link={{to: "/forgot-password", text: t`Forgot your password?`}} /></Col>

    return (
	    <React.Fragment>
	      <Card
	        className="border-radius-6 z-depth-4 animate fadeUp"
	        title={(!header ? t`Login` : "")}
	        actions={[
	          <FormErrors key="form-errors" errors={errors}/>,
	          <Row key="form-actions">
	            <Col s={12} className="input-field">
	              <FormActionButton
	                className="blue lighten-1 border-round col s12"
	                waves="light"
	                onClick={this.signIn}
	                text={(submitButtonTextOverride || t`Login`).toUpperCase()}
	                inProgress={requesting}/>
	            </Col>
	          </Row>,
	          <Row key="to-register-action">
	            <Col className="input-field" s={6}><FormSubAction onClick={onClickToSignup} link={{to: "/register", text: t`Register`}} /></Col>
	            {resetPasswordMarkup}
	          </Row>
	        ]}>

	        {!!header && header}
	      
	        <Row className="input-group">
	          <Row>
	            <Col s={12} className="row divider"></Col>
	          </Row>
	        </Row>
	        
	        <Row>
	          <TextInput
	            s={12}
	            icon="mail_outline"
	            label={t`Email`}
	            onChange={e => this.setState({email: e.target.value})}
	            value={this.state.email}/>
	        </Row>

	        <Row>
	          <TextInput
	            s={12}
	            label={t`Password`}
	            icon="lock_outline"
	            onChange={e => this.setState({password: e.target.value})}
	            value={this.state.password}
	            type="password"/>
	        </Row>

	        <Row>
	          <Col s={12}>
	            <Checkbox
	              value="remember"
	              label={t`Remember me`}/>
	          </Col>
	        </Row>

	        
	          
	      </Card>
	    	<LanguageSwitcher compact/>
	    </React.Fragment>
    )
  }
}

export default Login