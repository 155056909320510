/* REACT */
import React from "react"
import ReactDOM from "react-dom"

/* REDUX */
import { Provider } from "react-redux"
import ReduxStore from "store"

import {
	initLocale
} from "components/locale/init"

import App from "./app"

import "materialize-css/dist/css/materialize.min.css"
import "materialize-css/dist/js/materialize.min.js"
import "chartist/dist/chartist.min.css"
import "material-icons"
import "assets/scss/theme.scss"
import "assets/scss/style.scss"
import "assets/scss/app.scss"

initLocale();

const render = () => {
  ReactDOM.render(
  	<Provider store={ReduxStore}>
      <App />
    </Provider>,
    document.getElementById("root")
  )
}

render()
ReduxStore.subscribe(render)