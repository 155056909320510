import { connect } from "react-redux"

import {
	challengeGetAction,
	challengeEditAction,
	challengeEditedClearAction,
	challengeUpdateAction,
	challengeActivateToggleAction,
} from "../actions"

import ChallengesEdit from "./component" 

const mapStateToProps = state => ({
	token: state.client.token,
  challengeEdited: state.challengeEdited,
})

const actions = {
	challengeGetAction,
	challengeEditAction,
	challengeEditedClearAction,
	challengeUpdateAction,
	challengeActivateToggleAction,
}

export default connect(mapStateToProps, actions)(ChallengesEdit)