import React from "react"
import { t } from "ttag"
import {
  Row,
  Col,
  Card,
  TextInput,
} from "react-materialize"
import Breadcrumbs from "components/breadcrumbs"

import {
	Link,
} from "react-router-dom"

/* COMPONENTS */
import FormErrors from "components/form-errors"
import FormActionButton from "components/form-action-button"


const initialState = {
  data: {
  	currentPassword: "",
  	newPassword: "",
	  newPasswordConfirm: "",
	},
	errors: [],
}

class Register extends React.Component {

	state = {
		...initialState,
	}

  componentWillUnmount() {
    this.props.changePasswordClearAction()
  }

  getValidationErrors = () => {

  	const {data} = this.state
    
    if(data.currentPassword === "" || data.newPassword === "")
    	return [{key: t`Please type in your current password and choose a new password.`, name: "password"}]

    if(data.newPassword !== data.newPasswordConfirm)
      return [{key: t`Passwords do not match.`, name: "password"}]


    return [];
  }
  
  handleSubmit = () => {

    const {changePasswordRequestAction, requesting, cb} = this.props
    
    const errors = this.getValidationErrors()
    this.setState({errors})
    if(errors.length) {
      return
    }

    if(requesting)
      return

    const {currentPassword, newPassword} = this.state.data
    const {token} = this.props.client

    changePasswordRequestAction({
    	token,
    	currentPassword: currentPassword.trim(),
    	newPassword: newPassword.trim(),
      cb: cb,
    })
    
  }

  render() {

    const { requesting, success }  = this.props.changePassword
    const { data, errors } = this.state

    return (
    	<Row>

		  	<Breadcrumbs links={[
		  		{pathname: "/account", text: t`My account`},
		  		{pathname: "/account/change-password", text: t`Password change`},
		  	]}/>

				<Row>
					<Col s={12} m={5}>
						<Card
					  	className="animate fadeLeft"
					    title={t`Password change`}
					    actions={(!success ? [
			          <FormErrors errors={errors.concat(this.props.changePassword.errors)}/>,
			          <Row key="form-actions">
			            <Col s={12} className="input-field">
			              <FormActionButton
				            	className="blue lighten-1 border-round col s12"
				            	onClick={this.handleSubmit}
				            	text={t`Update`.toUpperCase()}
				            	inProgress={requesting}/>
			            </Col>
			          </Row>
			        ] : null)}>
		        	
		        	{!requesting && success && (
		        		<React.Fragment>
					        <h6>{t`Password has been changed.`}</h6>
					        <Link
					        	className="btn blue lighten-1 waves-effect"
					        	to="/account"
					        	>{t`My account`}</Link>
					      </React.Fragment>
		        	)}

		        	{!success && ( 

		        		<React.Fragment>
			        		<Row>
					          <TextInput
					          	s={12}
					          	m={12}
					          	name="newPassword"
					            label={t`Current password`}
					            onChange={e => this.setState({data: {...data, currentPassword: e.target.value}})}
					            value={data.currentPassword}
					            type="password"/>
					        </Row>

				          <Row>
					          <TextInput
					          	s={12}
					          	m={12}
					            label={t`New password`}
					            onChange={e => this.setState({data: {...data, newPassword: e.target.value}})}
					            value={data.newPassword}
					            type="password"/>
					        </Row>

				          <Row>
					          <TextInput
					          	s={12}
					          	m={12}
					            label={t`Repeat new password`}
					            onChange={e => this.setState({data: {...data, newPasswordConfirm: e.target.value}})}
					            value={data.newPasswordConfirm}
					            type="password"/>
					        </Row>

				          
				        </React.Fragment>
			        )}

        		</Card>
					</Col>
				</Row>

		  </Row>
    )
  }
}

export default Register