/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	changePasswordRequestAction,
	changePasswordClearAction,
	changePasswordValidationErrorAction,
} from "./actions"

/* THE COMPONENT */
import ChangePassword from "./component"

const mapStateToProps = state => ({
  client: state.client,
  changePassword: state.changePassword,
})

const actions = {
	changePasswordRequestAction,
	changePasswordClearAction,
	changePasswordValidationErrorAction,
}

export default connect(mapStateToProps, actions)(ChangePassword)