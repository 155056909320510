import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_CLEAR,
  CHANGE_PASSWORD_VALIDATION_ERROR
} from "./constants"

export function changePasswordRequestAction({token, currentPassword, newPassword}) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    token,
    currentPassword,
    newPassword,
  }
}

export function changePasswordClearAction() {
	return {
		type: CHANGE_PASSWORD_CLEAR
	}
}

export function changePasswordValidationErrorAction({ errors }) {
  return {
    type: CHANGE_PASSWORD_VALIDATION_ERROR,
    errors
  }
}