export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_ERROR = "REGISTER_ERROR"
export const REGISTER_VALIDATION_ERROR = "REGISTER_VALIDATION_ERROR"
export const REGISTER_CLEAR = "REGISTER_CLEAR"
export const FIELDS = [
	{
		type: "text",
		name: "displayName",
		label: "Name",
		icon: "person",
		grid: {
			s: 12,
			m: 12,
		},
		required: true,
		error: `Please type in your name.`,
	},
	{
		type: "email",
		name: "email",
		label: "Email",
		icon: "email_outline",
		grid: {
			s: 12,
		},
		required: true,
		error: `Please type in your email.`,
	},
	{
		type: "password",
		name: "password",
		label: "Password",
		icon: "lock_outline",
		grid: {
			s: 12,
		},
		required: true,
		error: `Please type in your password.`,
	},
	{
		type: "password",
		name: "passwordConfirm",
		label: "Repeat password",
		icon: "lock_outline",
		grid: {
			s: 12,
		},
		required: true,
		error: `Please type in your password again.`,
	},
]