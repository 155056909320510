/* REDUX */
import { connect } from "react-redux"

import {
	challengeTasksGetAction,
	challengeTasksCreateAction,
	challengeTasksUpdateAction,
	challengeTasksDeleteAction,
	challengeTaskEditedAction,
	challengeTaskEditedClearAction,
	challengeDayTaskCreateAction,
	challengeDayTaskCreateClearAction,
	challengeTaskChangeAction,
	challengeTaskDaysCollapseToggleAction,
} from "components/challenges/tasks/actions"

/* THE COMPONENT */
import ChallengeEditTasks from "./component" 

const mapStateToProps = state => ({
	token: state.client.token,
  challengeEdited: state.challengeEdited,
  challengeTaskEdited: state.challengeTaskEdited,
})

const actions = {
	challengeTasksGetAction,
	challengeTasksCreateAction,
	challengeTasksUpdateAction,
	challengeTasksDeleteAction,
	challengeTaskEditedAction,
	challengeTaskEditedClearAction,
	challengeDayTaskCreateAction,
	challengeDayTaskCreateClearAction,
	challengeTaskChangeAction,
	challengeTaskDaysCollapseToggleAction,
}

export default connect(mapStateToProps, actions)(ChallengeEditTasks)