/* REACT */
import React from "react"

/* REACT ROUTER DOM */
import { Router } from "react-router-dom"
import history from "client/history";
import ConditionedRoute from "components/_conditioned-route"

/* COMPONENTS */
import AppBg from "components/app-bg"
import AppBody from "components/app-body"
import SideMenu from "components/side-menu"
import NavBar from "components/navbar"

import AppCookieDisclaimer from "components/app-cookie-disclaimer"

import { ROUTES } from "components/routes"

const APP_OUTTER_ID = "app-outter"

export default () => (
	<Router history={history}>
		<div id={APP_OUTTER_ID}>

      <AppBg/>

      <NavBar/>
      <SideMenu />
			<AppBody />

			{!!ROUTES.length && ROUTES.map((route, key) => (
				<ConditionedRoute key={`c-route-${key}`} exact path={route.pathname} authenticated={route.authenticated} authRole={route.role || null} />
			))}


			<AppCookieDisclaimer />
      
		</div>
	</Router>
)