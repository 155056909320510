import React from "react"
import { gettext } from "ttag"
import {
	Button,
	Row,
	Col,
} from "react-materialize"
import MdClose from "react-ionicons/lib/MdClose"

import TaskTypeView from "components/challenges/tasks/task-type-view"
import InputTaskType from "components/challenges/tasks/input-task-type"
import InputTaskPoints from "components/challenges/tasks/input-task-points"
import InputTaskAnswers from "components/challenges/tasks/input-task-answers"
import InputTaskCategories from "components/challenges/tasks/input-task-categories"

import {
  matchShortcode,
  preventSubmitByEnter,
} from "services/functions"

const labels = {
  Day: `Day`,
  SavedOk: `Saved`,
  Task: `Task`,
  TypeInTaskName: `Type in task name`,
  PointsForCompletion: `Points for completion`,
  Save: `Save`,
  AreYouSureToRemoveThisTask: `Do you really want to remove this task?`,
  WatchYoutubeVideo: `Watch youtube video`,
  WatchVimeoVideo: `Watch vimeo video`,
  ShareLinkOnFacebook: `Share link on facebook`,
  TaskCompletion: `Task completion`,
}

const taskInputTypes = [
  { value: "NONE", label: `Without action` },
  { value: "TEXT", label: `By adding text` },
]

const TaskForm = ({
	task,
	onChangeHandler,
	onSubmitHandler,
	clearFormActionHandler,
	taskCategories,
	taskTypeOptions,
	taskType,
	clearTaskNameEdited,
}) => (
  <div className="challenges_form-days-task_create clear row">
    <Row
    	className="input-field">
    	{matchShortcode(task.name) ? (
	      <Col
	      	s={12}
	      	m={12}
	      	l={12}>
	        <label>{gettext(labels.Task)} {taskTypeOptions}</label>
        	<div>
        		<TaskTypeView {...{task, taskType, clearTaskNameEdited}}/>
        	</div>
        </Col>
      ) : (
      	<div className="input-field col s12 m12 l12">
      		<input
	      		type="text"
	      		data-key="name"
	      		onChange={onChangeHandler}
	      		value={task.name}
	        	onKeyUp={preventSubmitByEnter}/>
      		<label className="active">{gettext(labels.Task)} {taskTypeOptions}</label>
      	</div>
      )}
    </Row>
    <Row>
      <InputTaskPoints {...{task, labels, onChangeHandler, preventSubmitByEnter}}/>
    </Row>
    <Row>
      <InputTaskType {...{task, taskCategories, labels, onChangeHandler, taskInputTypes}} />
    </Row>
    {task.inputType === "CHOICE_A_D" && <InputTaskAnswers {...{task}} />}
    {taskCategories && <InputTaskCategories {...{task, labels, onChangeHandler, taskCategories}} />}
    <Row>
      <Col 
      	s={12}
      	m={12}
      	l={12}
      	className="task-actions">
        <Button className="blue lighten-1 mr5" onClick={onSubmitHandler}>{gettext(labels.Save)}</Button>
        <Button className="blue lighten-1" onClick={clearFormActionHandler}><MdClose color="#fff" fontSize="16px"/></Button>
      </Col>
    </Row>
  </div>
);

export default TaskForm