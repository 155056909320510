import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_CLEAR,
  LOGIN_CANCEL,
  LOGOUT,
  FACEBOOK_LOGIN_REQUEST,
} from "./constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    // Set the requesting flag and append a message to be shown
    case LOGIN_REQUEST:
      return {
        requesting: true,
        success: false,
        errors: [].concat(state.errors),
      }

    case FACEBOOK_LOGIN_REQUEST:
      return {
        requesting: true,
        success: false,
        errors: [].concat(state.errors),
      }

    case LOGIN_SUCCESS:
      return {
        errors: [],
        requesting: false,
        success: true,
      }

    case LOGIN_ERROR:
      return {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      }

    case LOGIN_CANCEL:
      return {
        requesting: false,
        success: false,
        errors: [],
      }

    case LOGIN_CLEAR:
      return {
        requesting: false,
        success: false,
        errors: [],
      }

    case LOGOUT:
      return {
        client: null,
        errors: [],
      }

    default:
      return state
  }
}