import { combineReducers } from "redux"
import client from "client/reducers"
import login from "components/login/reducers"
import forgotPassword from "components/forgot-password/reducers"
import register from "components/register/reducers"
import program from "components/daily-tasks/reducers/program"
import tasks from "components/daily-tasks/reducers/tasks"
import changePassword from "components/change-password/reducers"
import challengeCreated from "components/challenges/create/reducers"
import challengeEdited from "components/challenges/edit/reducers"
import challengesList from "components/challenges/list/reducers"
import challengeAccepted from "components/challenges/accept/reducers"
import challengeTaskEdited from "components/challenges/tasks/reducers"
import challengeStatsDays from "components/challenges/dashboard/components/days/reducers"
import challengeStatsUsers from "components/challenges/dashboard/components/users/reducers"
import challengeStatsUserTasks from "components/challenges/dashboard/components/users/tasks/reducers"
import referral from "components/referral/reducers"

const RootReducer = combineReducers({
  client,
  login,
  forgotPassword,
  register,
  program,
  tasks,
  changePassword,
  challengeCreated,
  challengeEdited,
  challengesList,
  challengeAccepted,
  challengeTaskEdited,
  challengeStatsDays,
  challengeStatsUsers,
  challengeStatsUserTasks,
  referral,
})

export default RootReducer