import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import UserTasks from "./component"

import {
	challengeGetAction,
	challengeEditedClearAction,
} from "components/challenges/actions"

import {
	challengeStatsUserTasksRequestAction,
	challengeStatsUserTasksClearAction,
	challengeStatsUserTasksFilterSet,
} from "./actions"

import {
	challengeStatsUsersRequestAction,
} from "components/challenges/dashboard/components/users/actions"

const mapStateToProps = state => ({
	token: state.client.token,
	challengeEdited: state.challengeEdited,
  challengeStatsUsers: state.challengeStatsUsers,
  challengeStatsUserTasks: state.challengeStatsUserTasks,
})

const actions = {
	challengeGetAction,
	challengeEditedClearAction,
	challengeStatsUserTasksRequestAction,
	challengeStatsUserTasksClearAction,
	challengeStatsUserTasksFilterSet,
	challengeStatsUsersRequestAction,
}

export default withRouter(connect(mapStateToProps, actions)(UserTasks))