import React from "react"

import {
	Navbar,
	Button,
} from "react-materialize"

import {
	BrandPng,
} from "components/brand"

const NavBar = ({authenticated}) => (
	<Navbar
		className={"main-nav black-text white" + (authenticated ? " authenticated" : "")}
		brand={
			<React.Fragment>
				{authenticated && <Button data-target="side-menu" className="btn-sidenav-toggle btn-floating btn-medium waves-effect hide-on-large-only show-on-large sidenav-trigger nav-trigger">
					<BrandPng />
				</Button>}
			</React.Fragment>
		} alignLinks="right">
	</Navbar>
)

export default NavBar