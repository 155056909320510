import Auth from "components/views/auth"
import Landing from "components/views/landing"
import Home from "components/views/home"

import {
  ROLE_USER_PAID,
} from "components/views/auth/constants"

export const ROUTES = [
	{
		pathname: "/",
		name: "today",
		authenticated: true,
		exact: true,
		component: Home,
	},
	{
		pathname: "/scoreboard",
		name: "scoreboard",
		authenticated: true,
		exact: true,
		component: Home,
	},
	{
		pathname: "/challenges",
		name: "challenges.index",
		authenticated: true,
		role: ROLE_USER_PAID,
		exact: true,
		component: Home,
		label: `My Challenges`,
		menu: true,
		menuIcon: "list",
	},
	{
		pathname: "/challenges/create",
		name: "challenges.create",
		authenticated: true,
		role: ROLE_USER_PAID,
		exact: true,
		component: Home,
		label: `Create challenge`,
		menu: true,
		menuIcon: "add",
	},
	{
		pathname: "/account",
		name: "account.index",
		authenticated: true,
		exact: true,
		component: Home,
		label: `My account`,
		menu: true,
		menuIcon: "person",
	},
	{
		pathname: "/challenges/:challengeId/edit",
		name: "challenges.create",
		authenticated: true,
		role: ROLE_USER_PAID,
		exact: true,
		component: Home,
	},
	{
		pathname: "/challenges/:challengeId/accept",
		name: "challenges.accept",
		exact: true,
		component: Landing,
	},
	{
		pathname: "/challenges/:challengeId/dashboard",
		name: "challenges.dashboard",
		authenticated: true,
		exact: true,
		component: Home,
	},
	{
		pathname: "/challenges/:challengeId/tasks/:userId",
		name: "challenges.user.tasks",
		authenticated: true,
		exact: true,
		component: Home,
	},
	{
		pathname: "/account/change-password",
		name: "account.change-password",
		authenticated: true,
		exact: true,
		component: Home,
	},
	{
		pathname: "/login",
		exact: true,
		component: Auth,
		authenticated: false,
	},
	{
		pathname: "/register",
		exact: true,
		component: Auth,
		authenticated: false,
	},
	{
		pathname: "/forgot-password",
		exact: true,
		component: Auth,
		authenticated: false,
	},
]