/* REACT */ 
import React from "react"
import IosArrowDown from "react-ionicons/lib/IosArrowDown"
import IosArrowUp from "react-ionicons/lib/IosArrowUp"

/* COMPONENTS */ 
import LoadingSpinner from "components/loading-spinner"
import ChallengeDayTasksHeading from "components/challenges/tasks/challenge-day-tasks-heading"
import ChallengeDayTasksCreate from "components/challenges/tasks/create"
import ChallengeDayTasksEdit from "components/challenges/tasks/edit"


import {
  matchYoutubeVideoUrl,
  matchVimeoVideoUrl,
  matchValidUrl,
} from "services/functions"

class ChallengeEditTasks extends React.Component {

  static defaultProps = {
    labels: {
      Day: `Day`,
      CreateTask: `Create task`,
      SavedOk: `Saved`,
      Task: `Task`,
      PointsForCompletion: `Points for completion`,
      Save: `Save`,
      AreYouSureToRemoveThisTask: `Do you really want to remove this task?`,
      WatchYoutubeVideo: `Watch youtube video`,
      ShareLinkOnFacebook: `Share link on facebook`,
      TaskCompletion: `Task completion`,
    },
    taskInputTypes: [
      { value: "NONE", label: `Without action` },
      { value: "TEXT", label: `By adding text` },
      { value: "CHOICE_A_D", label: `By selecting from multiple options` },
    ],
  }

  handleNewTaskValueChanged = (e) => {
    const key = e.target.dataset.key
    const value = e.target.value

    switch(key) {
      case "name":
        var parsed = [];
        const youtubeVideoUrl = matchYoutubeVideoUrl(value)
        if(youtubeVideoUrl) {
          parsed['youtube'] = youtubeVideoUrl
        }

        const vimeoVideoUrl = matchVimeoVideoUrl(value)
        if(vimeoVideoUrl) {
          parsed['vimeo'] = vimeoVideoUrl
        }

        const validUrl = matchValidUrl(value)
        if(validUrl) {
          parsed['fbshare'] = validUrl
        }

        var name = value;
        this.props.challengeTaskChangeAction({task: {name, parsed}})
      break;

      case "completeTime":
      	var completeTime = value
      	this.props.challengeTaskChangeAction({task: {completeTime}})
      break;

      case "points":
        if(value !== "" && isNaN(parseInt(value)))
          return;
        
        var points = value === "" ? value : parseInt(value)
        this.props.challengeTaskChangeAction({task: {points}})
      break;

      case "inputType":
        this.props.challengeTaskChangeAction({task: {inputType: value}})
      break;

      default:
      break;
    }
    
  }

  componentDidMount() {
    
    const {tasks} = this.props.challengeEdited
    if(!tasks)
      this.reloadTasks()
  }

  reloadTasks = () => {
    const {token} = this.props
    const {challenge} = this.props.challengeEdited
    this.props.challengeTasksGetAction({challengeId: challenge.id, token})
  }

  getNewTaskBlank() {
    return {
      answers: [],
      dayStart: null,
      dayEnd: null,
      inputType: "NONE",
      name: "",
      category: "MORNING",
      points: 0,
      location: null,
    }
  }

  handleDeleteTask = (taskId) => {

    const {labels} = this.props
    if(!window.confirm(labels.AreYouSureToRemoveThisTask))
      return;

    const {token} = this.props
    const {challenge} = this.props.challengeEdited
    this.props.challengeTasksDeleteAction({token, taskId, challengeId: challenge.id})
    
  }

  handleCreateTask = () => {

    const {token} = this.props
    const {challenge} = this.props.challengeEdited
    const {task} = this.props.challengeTaskEdited
    const {challengeDayTaskCreateClearAction} = this.props
    this.props.challengeTasksCreateAction({
      challengeId: challenge.id,
      task: task,
      token,
      cb: challengeDayTaskCreateClearAction,
    })
  }

  handleUpdateTask = () => {
    const {token} = this.props
    const {challenge} = this.props.challengeEdited
    const {task} = this.props.challengeTaskEdited
    const {challengeTaskEditedClearAction} = this.props
    this.props.challengeTasksUpdateAction({
      challengeId: challenge.id,
      task: task,
      token,
      cb: challengeTaskEditedClearAction,
    })
  }

  parseTaskType = (taskKey, type) => {
    const {task} = this.props.challengeTaskEdited
    if('undefined' === typeof task.parsed || 'undefined' === typeof task.parsed[type])
      return;

    this.props.challengeTaskChangeAction({task: { name: "[" + type + " " + task.parsed[type] + "]"}})

  }

  clearTaskNameEdited = () => {
    this.props.challengeTaskChangeAction({task: {name: ""}})
  }

  renderDayTasks = (day) => {
    if(this.isDayCollapsed(day))
      return false;

    const {labels, taskInputTypes} = this.props
    const {challenge, tasks} = this.props.challengeEdited
    const {
      handleNewTaskValueChanged,
      clearTaskNameEdited,
      parseTaskType,
      handleCreateTask,
      handleUpdateTask,
      handleDeleteTask,
    } = this

    const handlers = {
      handleNewTaskValueChanged,
      clearTaskNameEdited,
      parseTaskType,
      handleCreateTask,
      handleUpdateTask,
      handleDeleteTask,
    }

    const dayTasks = tasks.filter((task) => task.category !== "NOTIFY_CHAT").filter((task) => task.dayStart === day);
    const dayNotifications = tasks.filter((task) => task.category === "NOTIFY_CHAT").filter((task) => task.dayStart === day);
    return (
    	<ul className="challenges_form-days-task_list">

    		{!!dayNotifications.length && dayNotifications.map((task, key) => (
	        <ChallengeDayTasksEdit
	        	key={key}
	        	{...{
	        		labels,
	        		task,
	        		challenge,
	        		day,
	        		taskInputTypes,
	        		...handlers,
	        	}}
	        	/>
	      ))}

	      {!!dayTasks.length && dayTasks.map((task, key) => (
	        <ChallengeDayTasksEdit
	        	key={key}
	        	{...{
	        		labels,
	        		task,
	        		challenge,
	        		day,
	        		taskInputTypes,
	        		...handlers,
	        	}}
	        	/>
	      ))}

	      {!challenge.active && !this.isDayCollapsed(day) && (
	        <ChallengeDayTasksCreate
	        	{...{
	        		labels,
	        		challenge,
	        		day,
	        		taskInputTypes,
	        		...handlers
	        	}} />
	      )}
	    </ul>
	  )}

  isDayCollapsed(day) {
    const {collapsedDayIndexes} = this.props.challengeTaskEdited
    return collapsedDayIndexes.indexOf(day) !== -1;
  }

  renderDayTasksHeading(day) {
    const ArrowIcon = this.isDayCollapsed(day) ? IosArrowUp : IosArrowDown;
    const {labels, challengeTaskDaysCollapseToggleAction} = this.props

    return (
      <button href="toggle" className="challenges_form-days-task_list_item toggle" onClick={() => challengeTaskDaysCollapseToggleAction({day})} >
        <span className="challenges_form-label">{labels.Day + " " + day}</span> <ArrowIcon fontSize="16px"/>
      </button>
    )
  }

  renderDay(day) {
    const {labels, challengeTaskDaysCollapseToggleAction} = this.props
    return (
      <li key={"day-" + day} className="challenges_form-days-item">
        <ChallengeDayTasksHeading {...{labels, day, isDayCollapsed: this.isDayCollapsed(day), onTogglePress: () => challengeTaskDaysCollapseToggleAction({day})}} />
        {this.renderDayTasks(day)}
      </li>
    );
  }

  renderDays() {

    const {challenge} = this.props.challengeEdited

    let daysAndTasksMarkup = [];
    for(var d=1; d <= challenge.duration; d+=1)
      daysAndTasksMarkup.push(this.renderDay(d));

    return (
      <ul className="challenges_form-days row">{daysAndTasksMarkup}</ul>
    );

  }

  render() {

    const {tasks} = this.props.challengeEdited
    return (
      <div className="app-challenge-tasks-form row">
        {!tasks && <div className="app-card-head"><LoadingSpinner /></div>}
        {tasks && this.renderDays()}
      </div>
    );
  }

}

export default ChallengeEditTasks;