import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_VALIDATION_ERROR,
  FORGOT_PASSWORD_CLEAR
} from "./constants"

export function forgotPasswordRequestAction({ email }) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    email,
  }
}

export function forgotPasswordClearAction() {
	return {
		type: FORGOT_PASSWORD_CLEAR
	}
}

export function forgotPasswordValidationErrorAction({ errors }) {
  return {
    type: FORGOT_PASSWORD_VALIDATION_ERROR,
    errors,
  }
}