import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_VALIDATION_ERROR,
  REGISTER_CLEAR
} from "./constants"

const initialState = {
  requesting: false,
  success: false,
  errors: [],
}


export default function(state = initialState, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        requesting: true,
        success: false,
        errors: [].concat(state.errors),
      }

    case REGISTER_SUCCESS:
      return {
        errors: [],
        requesting: false,
        success: true,
      }

    case REGISTER_ERROR:
      return {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      }

    case REGISTER_VALIDATION_ERROR:
      return {
        requesting: false,
        success: false,
        errors: [].concat(action.errors),
      }

    case REGISTER_CLEAR:
      return {
        requesting: false,
        success: false,
        errors: [],
      }

    default:
      return state
  }
}
