/* REDUX */
import { connect } from "react-redux"

/* SAGA ACTIONS */
import {
	currentProgramAction,
	currentProgramClearAction,
	dailyTasksClearAction,
	taskToggleAction,
	taskInputChangeAction,
	taskVideoPlayingToggleAction,
	taskVideoPlaybackStartedAction
} from "components/daily-tasks/actions"

/* THE COMPONENT */ 
import DailyTasks from "./component"

const mapStateToProps = ({client, program, tasks}) => ({
	client,
  program,
  tasks,
})

const actions = {
	currentProgramAction,
	currentProgramClearAction,
	dailyTasksClearAction,
	taskToggleAction,
	taskInputChangeAction,
	taskVideoPlayingToggleAction,
	taskVideoPlaybackStartedAction
}

export default connect(mapStateToProps, actions)(DailyTasks)