/* REDUX */
import { connect } from "react-redux"

import {
	challengeTasksUpdateAction,
	challengeTasksDeleteAction,
	challengeTaskEditedAction,
	challengeTaskEditedClearAction,
} from "components/challenges/tasks/actions"

/* THE COMPONENT */
import {ChallengeDayTasksEdit} from "./component" 

const mapStateToProps = state => ({
  challengeEdited: state.challengeEdited,
  challengeTaskEdited: state.challengeTaskEdited,
})

const actions = {
	challengeTasksUpdateAction,
	challengeTasksDeleteAction,
	challengeTaskEditedAction,
	challengeTaskEditedClearAction,
}

export default connect(mapStateToProps, actions)(ChallengeDayTasksEdit)