import {
	addLocale,
	useLocale,
	setDefaultLang,
} from "ttag"

import {
	SUPPORTED_LOCALES,
	DEFAULT_LOCALE,
	LOCALE_STORAGE_KEY,
} from "./constants"

import {
  queryParam,
  stripQueryParam,
} from "services/functions"

import Storage from "services/storage"

export function getLocale() {
	const queryParamLocale = queryParam("loc")
	if(isLocaleValid(queryParamLocale)) {
		stripQueryParam("loc")
		return queryParamLocale
	}

  return Storage.getItem(LOCALE_STORAGE_KEY) || DEFAULT_LOCALE
}

export function isLocaleValid(locale) {
	return SUPPORTED_LOCALES.indexOf(locale) !== -1;
}

export function saveLocale(locale) {
	Storage.setItem(LOCALE_STORAGE_KEY, locale)
}

export function setLocale(locale) {
	
	const newLocale = locale === 'cz' ? 'cs' : locale;
	if(!isLocaleValid(newLocale) || newLocale === getLocale()) {
		return;
	}

  saveLocale(newLocale)
  window.location.reload()
}

export function initLocale() {
	const locale = getLocale()
	const translationsObj = require(`./i18n/${locale}.po.json`)
	addLocale(locale, translationsObj)
	setDefaultLang('sk')
	useLocale(locale)
}
