import React from "react"
import { t } from "ttag"
import LogoFacebook from "react-ionicons/lib/LogoFacebook"

export default ({shareLinkContentUrl, completed}) => (
	<div className="program-task-text">
	  <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_FACEBOOK_SHARER_URL + shareLinkContentUrl} className="btn btn-facebook btn-sm">
	    <LogoFacebook fontSize="16px" color="#fff"/> {t`Share on Facebook`}
	  </a>
	  {completed && <span className="program-task-video-overlay"/>}
	</div>
)